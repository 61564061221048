import axios from "axios";
import React, { useState } from "react";
import { useAuth } from "../../AuthProvider";
import { APIClient } from "../../utils/services";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_MUZE_API_HOST || "http://localhost:8002";
export default function CreateImagePage() {
  const { currentUser } = useAuth();
  const owner_id = currentUser?.email || "";
  const [positivePrompt, setPositivePrompt] = useState("");
  const [showNegativePrompt, setShowNegativePrompt] = useState(false);
  const [showGoToImageButton, setShowGoToImageButton] = useState(false);
  const [negativePrompt, setNegativePrompt] = useState("");
  const [selectedAspectRatio, setSelectedAspectRatio] = useState("16:9");
  const [generatedImageUrl, setGeneratedImageUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const navigate = useNavigate();

  const navigateToImageToVideo = () => {
    navigate("/app/image-to-video/image-select");
  };

  async function generateImageWithReplicate() {
    const service = new APIClient();

    const requestData = {
      cfg: 7,
      steps: 25,
      prompt: positivePrompt,
      aspect_ratio: selectedAspectRatio,
      output_format: "png",
      output_quality: 90,
      negative_prompt: showNegativePrompt ? negativePrompt : "",
      prompt_strength: 0.85,
    };

    try {
      setLoading(true);
      const url = `${BASE_URL}/generate_prompt_with_replicate/${owner_id}`;
      const response = await axios.post(url, requestData);

      if (Array.isArray(response.data) && response.data.length > 0) {
        setLoading(false);
        setGeneratedImageUrl(response.data[0]);

        const fileResponse = await axios.get(response.data[0], {
          responseType: "blob",
        });

        const blob = fileResponse.data;
        const uniqueFileName = `${Date.now()}_outIA.png`;

        const file = new File([blob], uniqueFileName, { type: "image/png" });

        service.upload_image_asset(owner_id, file, (response: any) => {
          toast.success("Sending image: " + uniqueFileName + " to library", {
            duration: 5000,
          });
          setShowGoToImageButton(true);
        });
      } else {
        toast.error("Something went wrong, please try again", {
          duration: 5000,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong, please try again", {
        duration: 5000,
      });
    }
  }

  const toggleOptionalInputs = () => {
    setShowNegativePrompt(!showNegativePrompt);
  };

  const handlePositivePromptChange = (e: any) => {
    setPositivePrompt(e.target.value);
  };

  const handleNegativePromptChange = (e: any) => {
    setNegativePrompt(e.target.value);
  };

  const handleAspectRatioChange = (e: any) => {
    setSelectedAspectRatio(e.target.value);
  };

  const handleImageClick = () => {
    download(generatedImageUrl);
  };

  const download = async (url: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = "generated_image.png";
    a.click();
    window.URL.revokeObjectURL(blobUrl);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div className="ps rounded m-3 d-flex flex-column flex-lg-row flex-grow-1 align-items-center justify-content-center">
          <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center border-lg-end border-bottom border-lg-bottom-0 h-100 p-3">
            <h1 className="mt-3 fw-bold text-center">Create an Image</h1>
            <div
              className="d-flex flex-column p-3 overflow-hidden w-100"
              style={{ height: "100%" }}
            >
              <div
                className="d-flex justify-content-center my-auto overflow-auto"
                style={{ height: "100%", width: "100%" }}
              >
                <div
                  className="d-flex flex-column p-3 overflow-hidden w-100"
                  style={{ height: "100%", cursor: "pointer" }}
                  onClick={handleImageClick}
                >
                  {!loading && generatedImageUrl && (
                    <img
                      onClick={handleImageClick}
                      src={generatedImageUrl}
                      title="Click on to download your image"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        cursor: "pointer",
                      }}
                      className="img-fluid"
                    />
                  )}
                </div>

                {loading && (
                  <div className="mt-2 p-5">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column p-5 justify-content-between h-100 w-100">
            <div className="d-flex flex-column text-center">
              <MySideBodyMobile
                positivePrompt={positivePrompt}
                showNegativePrompt={showNegativePrompt}
                negativePrompt={negativePrompt}
                selectedAspectRatio={selectedAspectRatio}
                handlePositivePromptChange={handlePositivePromptChange}
                handleNegativePromptChange={handleNegativePromptChange}
                handleAspectRatioChange={handleAspectRatioChange}
                toggleOptionalInputs={toggleOptionalInputs}
              />
            </div>
            <div className="d-flex flex-column align-items-center">
              {!loading && generatedImageUrl && (
                <div
                  className="create-video-btn btn mb-5"
                  onClick={handleImageClick}
                >
                  <button
                    onClick={handleImageClick}
                    style={{
                      border: "None",
                      backgroundColor: "transparent",
                      alignContent: "center",
                      marginRight: "5px",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Download image
                  </button>
                  <i
                    className="bi bi-cloud-download-fill"
                    style={{
                      fontSize: "20px",
                      borderRadius: "10px",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  ></i>{" "}
                </div>
              )}
              <button
                className="btn btn-success mt-auto mb-4 d-flex align-items-center"
                onClick={async () => {
                  try {
                    const success = await generateImageWithReplicate();
                    console.log(success);
                  } catch (error) {
                    console.error("Failed to generate image:", error);
                  }
                }}
              >
                <i className="bi bi-magic me-2"></i>
                Generate Image
              </button>
              {showGoToImageButton && (
                <button
                  className="btn btn-success mt-auto mb-4 d-flex align-items-center"
                  onClick={navigateToImageToVideo}
                >
                  <i className="bi bi-image me-2"></i>
                  Go to image to video
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="ps big-card rounded m-3 d-flex flex-grow-1 align-items-center justify-content-center">
          <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center border-end h-100 p-3">
            <h1 className="mt-3 fw-bold">Create an Image</h1>

            <div className="d-flex flex-column text-center">
              <MySideBody
                positivePrompt={positivePrompt}
                showNegativePrompt={showNegativePrompt}
                negativePrompt={negativePrompt}
                selectedAspectRatio={selectedAspectRatio}
                handlePositivePromptChange={handlePositivePromptChange}
                handleNegativePromptChange={handleNegativePromptChange}
                handleAspectRatioChange={handleAspectRatioChange}
                toggleOptionalInputs={toggleOptionalInputs}
              />
            </div>
            <div className="d-flex flex-column align-items-center">
              {!loading && generatedImageUrl && (
                <div
                  className="d-flex align-items-center mb-5"
                  onClick={handleImageClick}
                >
                  <button
                    style={{
                      border: "None",
                      backgroundColor: "transparent",
                      color: "#fff",
                      fontWeight: "bold",
                      marginRight: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Download image
                  </button>
                  <i
                    className="bi bi-cloud-download-fill"
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  ></i>{" "}
                </div>
              )}

              <div className="d-flex mb-4">
                <button
                  className="btn btn-success d-flex align-items-center me-2"
                  onClick={async () => {
                    try {
                      const success = await generateImageWithReplicate();
                      console.log(success);
                    } catch (error) {
                      console.error("Failed to generate image:", error);
                    }
                  }}
                >
                  <i className="bi bi-magic me-2"></i>
                  Generate Image
                </button>

                {showGoToImageButton && (
                  <button
                    className="btn create-video-btn d-flex align-items-center"
                    onClick={navigateToImageToVideo}
                  >
                    <i className="bi bi-image me-2"></i>
                    Go to image to video
                  </button>
                )}
              </div>
            </div>
            <div
              className="d-flex flex-column p-3 overflow-hidden"
              style={{ height: "100%" }}
            >
              <div
                className="d-flex justify-content-center my-auto overflow-auto"
                style={{ height: "100%", width: "100%" }}
              >
                <div
                  className="d-flex flex-column p-3 overflow-hidden "
                  style={{ height: "100%" }}
                >
                  {!loading && generatedImageUrl && (
                    <img
                      src={generatedImageUrl}
                      title={positivePrompt}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  )}
                </div>

                {loading && (
                  <div className="mt-2">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function MySideBodyMobile({
  positivePrompt,
  showNegativePrompt,
  negativePrompt,
  selectedAspectRatio,
  handlePositivePromptChange,
  handleNegativePromptChange,
  handleAspectRatioChange,
  toggleOptionalInputs,
}: {
  positivePrompt: any;
  showNegativePrompt: any;
  negativePrompt: any;
  selectedAspectRatio: any;
  handlePositivePromptChange: any;
  handleAspectRatioChange: any;
  handleNegativePromptChange: any;
  toggleOptionalInputs: any;
}) {
  return (
    <div className="ps py-3 text-center" style={{ minWidth: "14rem" }}>
      <label htmlFor="positivePromptInput">
        Describe what you want to see:
      </label>
      <div className="rounded prompt col-12">
        <input
          className="col-12"
          type="text"
          name="positivePromptInput"
          onChange={handlePositivePromptChange}
          value={positivePrompt}
          placeholder="Positive prompt"
          style={{
            border: "none",
            backgroundColor: "#0000",
            fontSize: "11px",
          }}
        />
      </div>

      <button
        type="button"
        className="btn"
        onClick={toggleOptionalInputs}
        style={{ fontWeight: "lighter", fontSize: "0.8em" }}
      >
        Negative prompt (optional){" "}
        {showNegativePrompt ? (
          <i className="bi bi-chevron-down"></i>
        ) : (
          <i className="bi bi-chevron-right"></i>
        )}
      </button>

      {showNegativePrompt && (
        <div className="rounded prompt col-12">
          <input
            type="text"
            onChange={handleNegativePromptChange}
            value={negativePrompt}
            placeholder="type here what you do not want see"
            className="col-12"
            style={{
              border: "none",
              backgroundColor: "#0000",
              fontSize: "11px",
            }}
          />
        </div>
      )}

      {/* Select Aspect Ratio */}
      <div style={{ marginTop: "20px" }}>
        <label htmlFor="aspectRatioSelect">Select Aspect Ratio:</label>
        <select
          id="aspectRatioSelect"
          value={selectedAspectRatio}
          onChange={handleAspectRatioChange}
          style={{ marginLeft: "10px", fontSize: "11px" }}
        >
          <option value="16:9">16:9</option>
          <option value="4:3">4:3</option>
          <option value="21:9">21:9</option>
          <option value="3:2">3:2</option>
          <option value="4:5">4:5</option>
          <option value="5:4">5:4</option>
          <option value="9:16">9:16</option>
        </select>
      </div>
    </div>
  );
}

function MySideBody({
  positivePrompt,
  showNegativePrompt,
  negativePrompt,
  selectedAspectRatio,
  handlePositivePromptChange,
  handleNegativePromptChange,
  handleAspectRatioChange,
  toggleOptionalInputs,
}: {
  positivePrompt: any;
  showNegativePrompt: any;
  negativePrompt: any;
  selectedAspectRatio: any;
  handlePositivePromptChange: any;
  handleAspectRatioChange: any;
  handleNegativePromptChange: any;
  toggleOptionalInputs: any;
}) {
  return (
    <div className="ps py-3 text-center" style={{ minWidth: "14rem" }}>
      <label htmlFor="positivePromptInput">
        Describe what you want to see:
      </label>
      <div className="rounded prompt" style={{ width: "600px" }}>
        <input
          className="col-12"
          type="text"
          name="positivePromptInput"
          onChange={handlePositivePromptChange}
          value={positivePrompt}
          placeholder="Positive prompt"
          style={{
            border: "none",
            backgroundColor: "#0000",
            fontSize: "11px",
            width: "580px",
          }}
        />
      </div>

      <button
        type="button"
        className="btn"
        onClick={toggleOptionalInputs}
        style={{ fontWeight: "lighter", fontSize: "0.8em" }}
      >
        Negative prompt (optional){" "}
        {showNegativePrompt ? (
          <i className="bi bi-chevron-down"></i>
        ) : (
          <i className="bi bi-chevron-right"></i>
        )}
      </button>

      {showNegativePrompt && (
        <div className="rounded prompt col-12">
          <input
            type="text"
            onChange={handleNegativePromptChange}
            value={negativePrompt}
            placeholder="type here what you do not want see"
            className="col-12"
            style={{
              border: "none",
              backgroundColor: "#0000",
              fontSize: "11px",
            }}
          />
        </div>
      )}

      {/* Select Aspect Ratio */}
      <div style={{ marginTop: "20px" }}>
        <label htmlFor="aspectRatioSelect">Select Aspect Ratio:</label>
        <select
          id="aspectRatioSelect"
          value={selectedAspectRatio}
          onChange={handleAspectRatioChange}
          style={{ marginLeft: "10px", fontSize: "11px" }}
        >
          <option value="16:9">16:9</option>
          <option value="4:3">4:3</option>
          <option value="21:9">21:9</option>
          <option value="3:2">3:2</option>
          <option value="4:5">4:5</option>
          <option value="5:4">5:4</option>
          <option value="9:16">9:16</option>
        </select>
      </div>
    </div>
  );
}

function Spinner() {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="spinner-border"
        style={{ width: "5rem", height: "5rem", color: "#ff30c4" }}
        role="status"
      />
    </div>
  );
}
