import { useLocation, useNavigate } from "react-router-dom";
import { APIClient } from "../../utils/services";
import { useEffect, useRef, useState } from "react";
import "./styles.css";
import { useAuth } from "../../AuthProvider";
import ReactPlayer from "react-player";
import toast from "react-hot-toast";
import { checkSimultaneousGenerationRestyle } from "../../utils/utils";
import { commonImageTypes, validFileType } from "../CreateAvatar/utils";
import { NextPrev } from "../AudioPage";
import { useCustomEventListener } from "react-custom-events";
import axios from "axios";
import usePaginator from "../Paginator/usePaginator";
import { useStartingFrame } from "../../store/hooks";
import Paginator from "../Paginator";
import { useGetImageAssetsQuery } from "../../API";
import ModalPurchase from "../GenerateVideo/ModalPurchase";
import ModalRemainCredits from "../GenerateVideo/ModalRemainCredits";

const TITLE = "Video Settings";
const SUBTITLE_IMAGE =
  "Optional: upload a reference image in the style that you want your video to be";
const HEADER = "Describe how you want your video to look";
const UPLOAD = "Upload";
const CFY_LYBRARY = "Choose from your library";
const MESSAGE = "Uploaded images will appears here.";

const imageBucketBaseURL = process.env.REACT_APP_IMAGES_BUCKET_URL;
const { REACT_APP_MUZE_API_HOST, REACT_APP_IMAGES_CDN_URL } = process.env;

export default function RestylePage() {
  const [isUpload, setIsUpload] = useState(true);
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [noImages, setNoImages] = useState(false);
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const location = useLocation();
  const [positivePrompt, setPositivePrompt] = useState("");
  const [ipadapterReferenceimage, setIpadapterReferenceimage] =
    useState("ip_image_9.png");
  const { videoInfo } = location.state || {};
  const [url, setUrl] = useState<any | undefined>(undefined);
  const [videoName, setVideoName] = useState<any | undefined>(undefined);
  const navigate = useNavigate();
  const [frameWidth, setFrameWidth] = useState(512);
  const [frameHeight, setFrameHeight] = useState(512);
  const [outputType, setOutputType] = useState("entire");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(false);
  const [showModalRemain, setShowModalRemain] = useState(false);

  const handleUpgradePlan = () => {
    navigate("/app/purchase");
  };

  const backToManagePlan = () => {
    navigate("/app/settings/billing");
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmallScreen = windowWidth < 1600;

  const handlePositivePromptChange = (e: any) => {
    setPositivePrompt(e.target.value);
  };

  const handleOutputTypeChange = (e: any) => {
    setOutputType(e.target.value);
  };

  const MIN_WIDTH_LANDSCAPE = 768;
  const MIN_HEIGHT_LANDSCAPE = 480;

  const MIN_WIDTH_PORTRAIT = 480;
  const MIN_HEIGHT_PORTRAIT = 768;

  const validateVideoDimensions = (width: number, height: number): boolean => {
    return (
      (width >= MIN_WIDTH_LANDSCAPE && height >= MIN_HEIGHT_LANDSCAPE) ||
      (width >= MIN_WIDTH_PORTRAIT && height >= MIN_HEIGHT_PORTRAIT)
    );
  };

  useEffect(() => {
    if (videoInfo) {
      setUrl(videoInfo.url);
      setVideoName(videoInfo.name);

      if (videoInfo.width === 0 && videoInfo.height === 0) {
        setFrameWidth(768);
        setFrameHeight(480);
      } else if (videoInfo.width === videoInfo.height) {
        setFrameWidth(512);
        setFrameHeight(512);
      } else if (videoInfo.width > videoInfo.height) {
        setFrameWidth(Math.min(videoInfo.width, 768));
        setFrameHeight(Math.min(videoInfo.height, 480));
      } else if (videoInfo.width < videoInfo.height) {
        setFrameWidth(Math.min(videoInfo.width, 480));
        setFrameHeight(Math.min(videoInfo.height, 768));
      }
    }
  }, [videoInfo]);

  async function restyleGenerate(
    owner_id: string,
    video_name: string,
    positivePrompt: string = "Synthwave, vibrant colors, neon lights, grid patterns, nostalgic, in the style of Retrowave, 8K",
    outputType: string,
    frameWidth: number,
    frameHeight: number,
    ipadapterReferenceimage: string = "ip_image_9.png"
  ) {
    const service = new APIClient();

    if (!positivePrompt) {
      positivePrompt =
        "Synthwave, vibrant colors, neon lights, grid patterns, nostalgic, in the style of Retrowave, 8K";
    }

    // Check if video_name contains spaces
    if (/\s/.test(video_name)) {
      toast.error(
        "Video name cannot contain spaces. Please use an underscore or hyphen instead.",
        {
          duration: 4000,
        }
      );
      return false;
    }

    // Validate frame dimensions
    if (!validateVideoDimensions(frameWidth, frameHeight)) {
      toast.error(
        `Frame dimensions are not acceptable. Please ensure the width and height meet at least one of the following criteria: ${MIN_WIDTH_LANDSCAPE}x${MIN_HEIGHT_LANDSCAPE} (landscape) or ${MIN_WIDTH_PORTRAIT}x${MIN_HEIGHT_PORTRAIT} (portrait).`,
        { duration: 6000 }
      );
      return false;
    }

    try {
      const canProceed = await checkSimultaneousGenerationRestyle(owner_id);
      if (!canProceed) {
        setShowModal(true); // Show the modal if can't proceed
        return false;
      }

      const response = await service.generate_restyle_video(
        owner_id,
        video_name,
        positivePrompt,
        outputType,
        frameWidth,
        frameHeight,
        ipadapterReferenceimage
      );

      // Check if the response has an error status
      if (response.statusCode === 200) {
        toast.success("Video restyling in progress...", { duration: 7000 });

        setTimeout(() => {
          navigate("/app/library/restyle-videos");
        }, 4000);

        return true;
      }
    } catch (error: any) {
      if (error.status === "ERROR") {
        setShowModalRemain(true);
      } else {
        toast.error("Failed to start video restyling. Please try again.", {
          duration: 4000,
        });
      }
      return false;
    }
  }

  const width = isSmallScreen ? "700px" : "730px";

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  useEffect(() => {
    const meta = document.createElement("meta");
    meta.setAttribute("name", "viewport");
    meta.setAttribute(
      "content",
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    );
    document.head.appendChild(meta);

    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  return (
    <>
      {isMobileDevice() ? (
        <div className="ps big-card rounded m-1 d-flex flex-grow-1 align-items-center justify-content-center">
          <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center border-end h-100 p-2">
            <h1 className="mt-3 fw-bold" style={{ fontSize: "24px" }}>
              {TITLE}
            </h1>
            <p style={{ fontSize: "14px" }}>{HEADER}</p>
            <div className="rounded prompt col-11">
              <div style={{ marginBottom: "3px" }}>
                <input
                  placeholder="Synthwave, vibrant colors, neon lights, grid patterns, nostalgic, in the style of Retrowave, 8K"
                  value={positivePrompt}
                  onChange={handlePositivePromptChange}
                  style={{
                    border: "none",
                    backgroundColor: "#0000",
                    fontSize: "10px",
                    width: "340px",
                    height: "100%",
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-grow-1 align-items-stretch flex-column">
              <div className="d-flex flex-column p-2 overflow-hidden flex-grow-1">
                <div className="rounded mt-3 mb-3">
                  <select
                    onChange={handleOutputTypeChange}
                    className="form-select"
                  >
                    <option value="entire">Full Restyle (Default)</option>
                    <option value="foreground">Affect foreground only</option>
                    <option value="background">Affect background only</option>
                  </select>
                </div>
                <div
                  style={{ width: "100%", height: "170px", overflow: "hidden" }}
                >
                  <ReactPlayer
                    url={url}
                    width="100%"
                    height="100%"
                    loop={true}
                    muted={false}
                    volume={0.8}
                    controls={true}
                  />
                </div>
              </div>

              <div className="d-flex flex-column p-2">
                <div className="mb-3">
                  <Tabs setIsUpload={setIsUpload} isUpload={isUpload} />
                </div>
                {isUpload && (
                  <UploadBtnMobile
                    setIsUpload={setIsUpload}
                    setIsLoadingProgress={setIsLoadingProgress}
                  />
                )}

                {!isUpload && (
                  <ChooseFromYourLibrary
                    setNoImages={setNoImages}
                    setIpadapterReferenceimage={setIpadapterReferenceimage}
                  />
                )}

                <div className="d-flex align-items-start mt-3 mb-2">
                  <div className="col-6">
                    <NextPrev hideNext />
                  </div>
                  <button
                    className="btn btn-success d-flex align-items-center"
                    onClick={async () => {
                      const success = await restyleGenerate(
                        ownerId,
                        videoName,
                        positivePrompt,
                        outputType,
                        frameWidth,
                        frameHeight,
                        ipadapterReferenceimage
                      );
                    }}
                  >
                    <i className="bi bi-magic me-2"></i>
                    Restyle
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ModalPurchase show={showModal} onHide={() => setShowModal(false)} />
          <ModalRemainCredits
            show={showModalRemain}
            onHide={() => setShowModalRemain(false)}
          />
        </div>
      ) : (
        <div className="ps big-card rounded m-3 d-flex flex-grow-1 align-items-center justify-content-center">
          <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center border-end h-100 p-3">
            <h1 className="mt-3 fw-bold">{TITLE}</h1>
            <p>{HEADER}</p>
            <div
              className={`rounded prompt ${isSmallScreen ? "col-9" : "col-6"}`}
            >
              <div style={{ marginBottom: "5px" }}>
                <input
                  placeholder="Synthwave, vibrant colors, neon lights, grid patterns, nostalgic, in the style of Retrowave, 8K"
                  value={positivePrompt}
                  onChange={handlePositivePromptChange}
                  style={{
                    border: "none",
                    backgroundColor: "#0000",
                    fontSize: "13px",
                    width: "690px",
                    height: "23px",
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-grow-1 align-items-stretch">
              <div className="d-flex flex-column p-3 overflow-hidden flex-grow-1">
                <div className="rounded mt-3 mb-3">
                  <select
                    onChange={handleOutputTypeChange}
                    className="form-select"
                  >
                    <option value="entire">Full Restyle (Default)</option>
                    <option value="foreground">Affect foreground only</option>
                    <option value="background">Affect background only</option>
                  </select>
                </div>
                <div
                  style={{
                    width: "500px",
                    height: "300px",
                    overflow: "hidden",
                  }}
                >
                  <ReactPlayer
                    url={url}
                    width="100%"
                    height="100%"
                    loop={true}
                    muted={false}
                    volume={0.8}
                    controls={true}
                  />
                </div>
              </div>

              <div className="d-flex flex-column p-3">
                <div className={`${isSmallScreen ? "mb-3" : ""}`}>
                  <Tabs setIsUpload={setIsUpload} isUpload={isUpload} />
                </div>
                {isUpload && (
                  <UploadBtn
                    setIsUpload={setIsUpload}
                    setIsLoadingProgress={setIsLoadingProgress}
                  />
                )}

                {!isUpload && (
                  <ChooseFromYourLibrary
                    setNoImages={setNoImages}
                    setIpadapterReferenceimage={setIpadapterReferenceimage}
                  />
                )}

                <div className="d-flex align-items-start mt-auto mb-2">
                  <div className="col-9">
                    <NextPrev hideNext />
                  </div>
                  <button
                    className="btn btn-success d-flex align-items-center"
                    onClick={async () => {
                      const success = await restyleGenerate(
                        ownerId,
                        videoName,
                        positivePrompt,
                        outputType,
                        frameWidth,
                        frameHeight,
                        ipadapterReferenceimage
                      );
                    }}
                  >
                    <i className="bi bi-magic me-2"></i>
                    Restyle
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ModalPurchase show={showModal} onHide={() => setShowModal(false)} />
          <ModalRemainCredits
            show={showModalRemain}
            onHide={() => setShowModalRemain(false)}
          />
        </div>
      )}
    </>
  );
}

function Tabs(props: any) {
  const { isUpload, setIsUpload } = props;

  return (
    <div className="d-flex flex-column overflow-auto gap-3">
      <div className="d-flex justify-content-center gap-5">
        <div
          onClick={() => setIsUpload(true)}
          style={{ borderBottom: isUpload ? "2px #FF30C4 solid" : "" }}
        >
          {UPLOAD}
        </div>
        <div
          onClick={() => setIsUpload(false)}
          style={{ borderBottom: !isUpload ? "2px #FF30C4 solid" : "" }}
        >
          {CFY_LYBRARY}
        </div>
      </div>
    </div>
  );
}

function UploadBtn(props: any) {
  const { setIsUpload, setIsLoadingProgress } = props;
  const API_KEY = process.env.REACT_APP_MUZE_API_KEY;
  const client = new APIClient();
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const inputFile = useRef<HTMLInputElement | null>(null);
  const onClick = async () => inputFile.current?.click();

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsLoadingProgress(true);

    const droppedFiles = Array.from(e.dataTransfer.files);

    client.authenticate(undefined, undefined, API_KEY);

    if (droppedFiles.length > 0) {
      droppedFiles.forEach((file) => {
        if (!validFileType(file, commonImageTypes)) {
          setIsLoadingProgress(false);
          toast.error(`Format not allowed:  ${file.name}`, {
            duration: 5000,
          });
          return;
        }

        client.upload_image_asset_restyle(ownerId, file, (response: any) => {
          if (response?.statusText === "OK") {
            toast.success(`Image upload success! ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
            setIsUpload(false);
          } else {
            toast.error(`Image upload failed:  ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
          }
        });
      });
    }
  };

  const handleChange = async (e: any) => {
    setIsLoadingProgress(true);
    const files = e.target.files;
    client.authenticate(undefined, undefined, API_KEY);

    if (files.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        if (!validFileType(file, commonImageTypes)) {
          setIsLoadingProgress(false);
          toast.error(`Format not allowed:  ${file.name}`, {
            duration: 5000,
          });
          continue;
        }
        setIsLoadingProgress(true);
        client.upload_image_asset_restyle(ownerId, file, (i: any) => {
          if (i?.statusText === "OK") {
            toast.success(`Image upload success! ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
            setIsUpload(false);
          } else {
            toast.error(`Image upload failed:  ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
          }
        });
      }
    }
  };

  const inp = {
    id: "file",
    ref: inputFile,
    style: { display: "none" },
    accept: "image/png, image/jpeg",
  };

  return (
    <div
      className="upload-btn"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      {...{ onClick }}
    >
      <input type="file" multiple {...inp} onChange={handleChange} />
      <i className="bi bi-upload" />
      <div
        style={{ fontSize: "small" }}
        className="align-items-center justify-content-center p-4"
      >
        <p className="text-center">{SUBTITLE_IMAGE}</p>
      </div>
    </div>
  );
}

function UploadBtnMobile(props: any) {
  const { setIsUpload, setIsLoadingProgress } = props;
  const API_KEY = process.env.REACT_APP_MUZE_API_KEY;
  const client = new APIClient();
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const inputFile = useRef<HTMLInputElement | null>(null);
  const onClick = async () => inputFile.current?.click();

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsLoadingProgress(true);

    const droppedFiles = Array.from(e.dataTransfer.files);

    client.authenticate(undefined, undefined, API_KEY);

    if (droppedFiles.length > 0) {
      droppedFiles.forEach((file) => {
        if (!validFileType(file, commonImageTypes)) {
          setIsLoadingProgress(false);
          toast.error(`Format not allowed:  ${file.name}`, {
            duration: 5000,
          });
          return;
        }

        client.upload_image_asset_restyle(ownerId, file, (response: any) => {
          if (response?.statusText === "OK") {
            toast.success(`Image upload success! ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
            setIsUpload(false);
          } else {
            toast.error(`Image upload failed:  ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
          }
        });
      });
    }
  };

  const handleChange = async (e: any) => {
    setIsLoadingProgress(true);
    const files = e.target.files;
    client.authenticate(undefined, undefined, API_KEY);

    if (files.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        if (!validFileType(file, commonImageTypes)) {
          setIsLoadingProgress(false);
          toast.error(`Format not allowed:  ${file.name}`, {
            duration: 5000,
          });
          continue;
        }
        setIsLoadingProgress(true);
        client.upload_image_asset_restyle(ownerId, file, (i: any) => {
          if (i?.statusText === "OK") {
            toast.success(`Image upload success! ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
            setIsUpload(false);
          } else {
            toast.error(`Image upload failed:  ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
          }
        });
      }
    }
  };

  const inp = {
    id: "file",
    ref: inputFile,
    style: { display: "none" },
    accept: "image/png, image/jpeg",
  };

  return (
    <div
      className="upload-btn-mobile"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      {...{ onClick }}
    >
      <input type="file" multiple {...inp} onChange={handleChange} />
      <i className="bi bi-upload" />
      <div
        style={{ fontSize: "small" }}
        className="align-items-center justify-content-center p-2"
      >
        <p className="text-center">{SUBTITLE_IMAGE}</p>
      </div>
    </div>
  );
}

function ChooseFromYourLibrary({
  setNoImages,
  setIpadapterReferenceimage,
}: {
  setNoImages: any;
  setIpadapterReferenceimage: any;
}) {
  const { refetch, isLoading, isError } = useImageLibrary();
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const paginator = usePaginator();
  const { actual, setTotal } = paginator;
  const page = actual;
  const perPage = 10;
  const [formatedImages, setFormatedImages] = useState<any[]>([]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setNoImages(formatedImages.length === 0);
  }, [formatedImages.length, setNoImages]);

  const getImages = () => {
    axios
      .get(
        `${REACT_APP_MUZE_API_HOST}/initial-image/assets/restyle?owner_id=${encodeURIComponent(
          ownerId
        )}&page=${page}&per_page=${perPage}`
      )
      .then((res: any) => {
        if (res.data.results.length === 0 || !Array.isArray(res.data.results)) {
          setTotal(0);
          setFormatedImages([]);
          return;
        }
        const formatedImages = res.data.results.map(
          (el: any, index: number, array: any[]) => ({
            src: `${REACT_APP_IMAGES_CDN_URL}restyle/${encodeURIComponent(
              ownerId
            )}/${encodeURIComponent(el.Key)}`,
            name: el.Key,
            date: new Date(el.LastModified).toLocaleDateString(),
            isLast: index === array.length - 1,
          })
        );
        setTotal(res.data.total);
        setFormatedImages(formatedImages);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {});
  };

  useCustomEventListener("onImageUpload", () => {
    getImages();
  });

  useEffect(() => {
    getImages();
  }, [actual]);

  return (
    <div className="choose-from-library overflow-hidden">
      {!isLoading && !isError && (
        <>
          <div className="d-flex flex-wrap justify-content-start overflow-auto p-3 gap-3">
            {formatedImages.length > 0 ? (
              formatedImages.map((image: any) => (
                <ImageCard
                  key={image.name}
                  {...image}
                  setIpadapterReferenceimage={setIpadapterReferenceimage}
                />
              ))
            ) : (
              <div>{formatedImages.length === 0 ? MESSAGE : null}</div>
            )}
          </div>
          <Paginator {...paginator} />
        </>
      )}
      {isLoading && <Loading />}
      {isError && <Error />}
    </div>
  );
}

function useImageLibrary() {
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const paginator = usePaginator();
  const { actual, setTotal, setItemsPerPage, itemsPerPage } = paginator;
  const page = actual;
  const perPage = itemsPerPage;
  const { data, isLoading, refetch, isError } = useGetImageAssetsQuery({
    ownerId,
    page,
    perPage,
  });
  const { setUserInitialImageKey, initialImageKey } = useStartingFrame();

  const images = Array.isArray(data?.results)
    ? data.results.map((i: any, k: number, l: any[]) => ({
        img: `${imageBucketBaseURL}/${ownerId}/${i.Key}`,
        id: i.Key,
        isLast: k === l.length - 1,
      }))
    : [];

  return {
    refetch,
    images,
    isLoading,
    isError,
    setUserInitialImageKey,
    initialImageKey,
  };
}

function ImageCard(props: any) {
  const { src, name, setIpadapterReferenceimage } = props;
  const [isSelected, setIsSelected] = useState(false);

  const downloadImage = () => {
    const link = document.createElement("a");
    link.href = src;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClick = () => {
    setIsSelected(!isSelected); // Toggle selection
    setIpadapterReferenceimage(!isSelected ? src : "ip_image_9.png");
  };

  const className = `d-flex flex-column align-items-center justify-content-center image-card ${
    isSelected ? "image-selected" : ""
  }`;

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div
          className={className}
          onClick={handleClick}
          style={{
            width: "100%",
            marginBottom: "0.5rem",
            marginRight: "10px",
            maxWidth: "44%",
            position: "relative",
          }}
        >
          <img
            className="d-flex flex-column align-items-center justify-content-center"
            src={`${src}`}
            alt="initial frame"
          />
          <button
            className="btn btn-download position-absolute"
            onClick={downloadImage}
            style={{
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "70%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <i
              className="bi bi-cloud-arrow-down-fill"
              style={{ fontSize: "large" }}
            ></i>
          </button>
        </div>
      ) : (
        <div className={`${className} position-relative`} onClick={handleClick}>
          <button
            className="btn btn-download position-absolute"
            onClick={downloadImage}
            style={{
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "70%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <i
              className="bi bi-cloud-arrow-down-fill"
              style={{ fontSize: "large" }}
            ></i>
          </button>
          <img
            className="d-flex flex-column align-items-center justify-content-center"
            src={`${src}`}
            alt="initial frame"
            style={{
              width: "100%",
              height: "100%",
              display: "block",
            }}
          />
        </div>
      )}
    </>
  );
}

function Loading() {
  return (
    <div className="d-flex flex-grow-1 align-items-center justify-content-center">
      <h3>Loading ...</h3>
    </div>
  );
}

function Error() {
  return (
    <div className="d-flex flex-grow-1 flex-column gap-3 align-items-center justify-content-center">
      <h5>Something went wront ...</h5>
      <p>Please try later.</p>
    </div>
  );
}
