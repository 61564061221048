import { Button, Form, Nav, Stack } from "react-bootstrap";
import { APIClient } from "../../utils/services";
import { deleteUserFB } from "../../utils/fb-services";
import { useState } from "react";
import { useAuth } from "../../AuthProvider";
import { useNavigate } from "react-router-dom";

const API_KEY = process.env.REACT_APP_MUZE_API_KEY;

const deleteUser = (uid: string, password: string, signout: Function) => {
  const client = new APIClient();
  client.authenticate(undefined, undefined, API_KEY);

  deleteUserFB(password, () => {
    client.delete_user(uid); // delete on dynamoDB (API)
    signout(() => {});
  });
};

export default function Navigator() {
  const [pass, setPass] = useState("");
  const [showConfirm, setConfirm] = useState(false);
  const { currentUser, logOut } = useAuth();
  const navigate = useNavigate();

  const onClick = () => {
    if (showConfirm) {
      deleteUser(currentUser?.uid || "", pass, logOut);
      setConfirm(false);
    } else {
      setConfirm(true);
    }
  };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div className="d-flex flex-column">
          <Nav
            fill
            variant="pills"
            defaultActiveKey="edit-profile"
            className="mb-1 pb-1 border-bottom d-flex flex-wrap"
            onSelect={(selectedKey) => navigate(`${selectedKey}`)}
          >
            {buttons.map(Item())}
          </Nav>
          <DeleteAccount {...{ showConfirm, setConfirm, setPass, onClick }} />
        </div>
      ) : (
        <Stack style={{ maxWidth: "max-content" }} className="ps">
          <Nav
            fill
            variant="pills"
            defaultActiveKey="edit-profile"
            className="mb-4 pb-3 border-bottom flex-column"
            onSelect={(selectedKey) => navigate(`${selectedKey}`)}
          >
            {buttons.map(Item())}
          </Nav>
          <DeleteAccount {...{ showConfirm, setConfirm, setPass, onClick }} />
        </Stack>
      )}
    </>
  );
}

function Item() {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const classNameDesktop = `d-flex justify-content-center align-items-center text-start text-muted fs-6 p-2 px-4 text-nowrap`;
  const classNameMobile = `d-flex justify-content-start align-items-center text-start text-muted fs-6 p-2 px-4 text-nowrap`;

  const className = isMobileDevice() ? classNameMobile : classNameDesktop;

  return (i: any, k: number) => (
    <Nav.Item key={k}>
      <Nav.Link {...{ className, eventKey: i.path }}>
        <i className={`bi bi-${i.icon} fs-5 me-${isMobileDevice() ? 4 : 2}`} />
        {i.label}
      </Nav.Link>
    </Nav.Item>
  );
}

function DeleteAccount(props: any) {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const { showConfirm, setConfirm, setPass, onClick } = props;
  return (
    <>
      {showConfirm && (
        <Form.Group>
          <Form.Label>
            Confirm your password
            <Form.Control
              inputMode="text"
              placeholder="********"
              onChange={(e) => setPass(e.target.value)}
            />
          </Form.Label>
        </Form.Group>
      )}
      {!isMobileDevice() && (
        <Button {...{ onClick }} className="btn-danger">
          <i className="bi bi-x" />
          <span>Delete account</span>
        </Button>
      )}
      {showConfirm && (
        <Button onClick={() => setConfirm(false)} className="btn-dark">
          <i className="bi bi-x" />
          <span>Cancel</span>
        </Button>
      )}
    </>
  );
}

const buttons = [
  {
    label: "Your Profile",
    icon: "person-fill",
    path: "edit-profile",
  },
  {
    label: "Billing",
    icon: "bell-fill",
    path: "billing",
  },
  // {
  //   label: "Admin",
  //   icon: "person-vcard",
  //   path: "admin",
  // },
];
