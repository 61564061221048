import { Link, useLocation } from "react-router-dom";
import "../../routes/styles.css";

export default function Navigator({ setMenuOpen, menuOpen, onItemClick }: any) {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div className="overflow-auto">
      {Menus.map((menu, index) => (
        <MenuItem
          {...menu}
          key={index}
          setMenuOpen={setMenuOpen}
          menuOpen={menuOpen}
          onItemClick={onItemClick}
          isMobileDevice={isMobileDevice()} // Pass isMobileDevice prop to MenuItem
        />
      ))}
    </div>
  );
}

function MenuItem({
  to,
  label,
  icon,
  setMenuOpen,
  menuOpen,
  onItemClick,
  isMobileDevice, // Receive isMobileDevice prop
}: any) {
  const pausePath =
    "M12.404 2.364A2.364 2.364 0 0 0 10.041 0H6.134a2.364 2.364 0 0 0-2.363 2.364v27.273A2.364 2.364 0 0 0 6.134 32h3.907a2.364 2.364 0 0 0 2.363-2.363V2.364Zm15.826 0A2.364 2.364 0 0 0 25.866 0H21.96a2.364 2.364 0 0 0-2.364 2.364v27.273A2.364 2.364 0 0 0 21.96 32h3.906a2.364 2.364 0 0 0 2.364-2.363V2.364Z";
  const pause = () =>
    document.querySelectorAll("path").forEach((i) => {
      if (i.getAttribute("d") === pausePath) {
        i.parentElement?.parentElement?.click();
      }
    });

  const l = useLocation();
  const active = l.pathname
    .split("/")
    .includes(to.split("/")[to.split("/").length - 1])
    ? "nav-active"
    : "";
  const className = `btn text-start text-white d-flex gap-3 align-items-center ${active}`;

  // Conditionally render onClick function based on isMobileDevice
  const onClick = isMobileDevice
    ? () => {
        pause();
        setMenuOpen(!menuOpen);
        onItemClick(to);
      }
    : () => {
        pause();
      };

  return (
    <Link {...{ to, className, onClick }}>
      <i
        className={`bi ${icon}`}
        style={{ color: active ? "#FF30C4" : "#fff" }}
      />
      {label}
    </Link>
  );
}

const Menus = [
  {
    label: "Dashboard",
    icon: "bi-house-door",
    to: "dashboard",
  },
  {
    label: "Template",
    icon: "bi-rocket",
    to: "template",
  },
  {
    label: "Create a video",
    icon: "bi-camera-reels",
    to: "create-video",
  },
  {
    label: "Restyle a video",
    icon: "bi-brush",
    to: "restyle-video",
  },
  {
    label: "Image to video",
    icon: "bi-image",
    to: "image-to-video",
  },
  {
    label: "Create an image",
    icon: "bi-camera2",
    to: "create-an-image",
  },
  {
    label: "Create an Avatar",
    icon: "bi-person",
    to: "create-avatar",
  },
  {
    label: "Your projects",
    icon: "bi-folder",
    to: "library",
  },
];
