import axios from "axios";
import { useEffect, useState } from "react";

export function usePlans() {
  const [plans, setPlans] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  function refetch() {
    setIsLoading(true);
    axios
      .get(PLANS_URL)
      .then((response) => {
        if (response?.data?.status === "SUCCESS") {
          if (response?.data?.plans?.length > 0) {
            setPlans(response?.data?.plans || []);
            setIsError(false);
          }
        } else {
          setIsError(true);
        }
      })
      .catch((e) => {
        setIsError(true);
        console.error(e);
      });
    setIsLoading(false);
  }

  const BASE_URL =
    process.env.REACT_APP_MUZE_API_HOST || "http://localhost:8002";
  const PLANS_URL = `${BASE_URL}/plans`;

  useEffect(() => {
    refetch();
  }, []);

  return { plans, refetch, isLoading, isError };
}
