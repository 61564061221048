import { useEffect, useState } from "react";
import { Plans } from "../../Subscription";
import { useAuth } from "../../../AuthProvider";
import Login from "../../Login";
import { useCheckout } from "../../Subscription/useCheckout";
import { useNavigate } from "react-router-dom";
import ModalStyle from "../../Subscription/modalStyle";

export default function Pricing() {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { currentUser } = useAuth();

  const {
    openCheckout,
    showSuccessModal,
    showErrorModal,
    setShowSuccessModal,
    setShowSuccessFirstSubs,
    showSuccessFirstSubs,
    setShowErrorModal,
    setShowMessageDowngrade,
    showMessageDowngrade,
    loading,
    setUpdateTrialingSub,
    showUpdateTrialingSub,
  } = useCheckout(gotoGV);

  function gotoGV(v: any) {
    if (v.name === "checkout.completed") {
      setShowSuccessFirstSubs(true);
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function goToDashboard() {
    navigate("/app/dashboard");
  }

  const marginTop = {
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    margin: "auto",
  } as const;

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const closeButtonStyle: React.CSSProperties = {
    position: "absolute",
    top: isMobileDevice() ? "125px" : "35px", // Adjust top position based on mobile or desktop
    right: isMobileDevice() ? "20px" : "45px", // Adjust right position based on mobile or desktop
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "28px",
    color: "#fff",
  };

  if (windowWidth < 1600 && !isMobileDevice()) {
    closeButtonStyle.right = "-170px";
  }

  if (!currentUser) {
    return (
      <div className="d-flex flex-column flex-grow-1" style={marginTop}>
        <div className="d-flex flex-column m-auto" style={marginTop}>
          <Login />
        </div>
      </div>
    );
  }

  const titleStyle =
    windowWidth < 1700
      ? { fontWeight: "bolder", marginBottom: "0.3rem" }
      : { fontWeight: "bolder", marginBottom: "1rem" };

  const subtitleStyle = {
    marginBottom: isMobileDevice() || windowWidth < 1700 ? "5rem" : "3rem",
  };

  const containerStyle = {
    backgroundColor: "#232627",
    height: "100%",
    overflow: isMobileDevice() ? "auto" : "initial",
  };

  return (
    <>
      {isMobileDevice() ? (
        <div
          className={`ps d-flex flex-column justify-content-start flex-grow-1 text-center ${
            windowWidth < 1700 ? "p-1" : "p-2"
          }`}
          style={containerStyle}
        >
          <button onClick={goToDashboard} style={closeButtonStyle}>
            &times;
          </button>
          <h3 className="text-center" style={titleStyle}>
            {title}
          </h3>
          <span
            className="text-muted"
            style={{ ...subtitleStyle, fontSize: "small" }}
          >
            {subtitle}
          </span>
          <div style={{ height: "150%" }}>
            <Plans />
            {showSuccessModal && (
              <ModalStyle
                show={showSuccessModal}
                onHide={() => setShowSuccessModal(false)}
                name="Success"
                description="Your subscription was updated successfully!"
                onStartCreating={goToDashboard}
                buttonLabel="Continue Creating"
              />
            )}
            {showUpdateTrialingSub && (
              <ModalStyle
                show={showUpdateTrialingSub}
                onHide={() => setUpdateTrialingSub(false)}
                name="Success"
                description="Your trialing subscription was updated successfully!"
                onStartCreating={goToDashboard}
                buttonLabel="Continue Creating"
              />
            )}
            {showSuccessFirstSubs && (
              <ModalStyle
                show={showSuccessFirstSubs}
                onHide={() => setShowSuccessFirstSubs(false)}
                name="Success"
                description="Congrats! Your subscription was successful!"
                onStartCreating={goToDashboard}
                buttonLabel="Start Creating"
              />
            )}
            {showErrorModal && (
              <ModalStyle
                show={showErrorModal}
                onHide={() => setShowErrorModal(false)}
                name="Error"
                description="Your subscription could not be updated at this time. Please try again later."
                onStartCreating={goToDashboard}
                buttonLabel="Ok"
              />
            )}
            {showMessageDowngrade && (
              <ModalStyle
                show={showMessageDowngrade}
                onHide={() => setShowMessageDowngrade(false)}
                name="Success"
                description="Your subscription will be changed on the next billing period."
                onStartCreating={goToDashboard}
                buttonLabel="Ok"
              />
            )}
          </div>
        </div>
      ) : (
        <div
          className={`ps d-flex flex-column justify-content-start flex-grow-1 text-center ${
            windowWidth < 1700 ? "p-1" : "p-2"
          }`}
          style={containerStyle}
        >
          <button onClick={goToDashboard} style={closeButtonStyle}>
            &times;
          </button>
          <h1 className="text-center" style={titleStyle}>
            {title}
          </h1>
          <h5 className="text-muted" style={subtitleStyle}>
            {subtitle}
          </h5>
          <div style={{ height: "100%" }}>
            <Plans />
            {showSuccessModal && (
              <ModalStyle
                show={showSuccessModal}
                onHide={() => setShowSuccessModal(false)}
                name="Success"
                description="Your subscription was updated successfully!"
                onStartCreating={goToDashboard}
                buttonLabel="Continue Creating"
              />
            )}
            {showUpdateTrialingSub && (
              <ModalStyle
                show={showUpdateTrialingSub}
                onHide={() => setUpdateTrialingSub(false)}
                name="Success"
                description="Your trialing subscription was updated successfully!"
                onStartCreating={goToDashboard}
                buttonLabel="Continue Creating"
              />
            )}
            {showSuccessFirstSubs && (
              <ModalStyle
                show={showSuccessFirstSubs}
                onHide={() => setShowSuccessFirstSubs(false)}
                name="Success"
                description="Congrats! Your subscription was successful!"
                onStartCreating={goToDashboard}
                buttonLabel="Start Creating"
              />
            )}
            {showErrorModal && (
              <ModalStyle
                show={showErrorModal}
                onHide={() => setShowErrorModal(false)}
                name="Error"
                description="Your subscription could not be updated at this time. Please try again later."
                onStartCreating={goToDashboard}
                buttonLabel="Ok"
              />
            )}
            {showMessageDowngrade && (
              <ModalStyle
                show={showMessageDowngrade}
                onHide={() => setShowMessageDowngrade(false)}
                name="Success"
                description="Your subscription will be changed on the next billing period."
                onStartCreating={goToDashboard}
                buttonLabel="Ok"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

const title = "Pricing";
const subtitle =
  "14 day free trial and non-expiring credits for infinite creativity";
