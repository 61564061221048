export class UpscalingSettings {
  constructor(ownerId: string, videoAssetId: string) {
    this.owner_id = ownerId;
    this.video_asset_id = videoAssetId;
  }

  owner_id: string = "";
  video_asset_id: string = "";
  upscaling_settings: Settings = new Settings();
}

export class Settings {
  vid_path: string = "";
  upscale_model: string = "realesr-animevideov3";
  upscale_factor: string = "x4";
  crf: number = 17;
  preset: string = "slow";
  keep_imgs: string = "True";
  out_img_format: string = "png";
}
