import { useEffect, useState } from "react";
import useAvatarSelection from "./useAvatarSelection";
import "./styles.css";
import { useAvatar } from "../../store/hooks";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthProvider";
import { useGetAvatarsQuery, useGetMetadataDefaultQuery } from "../../API";
import { useArtStyleSelector } from "../ChooseArtStylePage";
import { getAvatarsList } from "./avatarsHelpers";

const TITLE = "Avatar";
const DESCRIPTION1 = "Place your avatar in the video (optional)";
const DESCRIPTION2 = "Choose from your Avatar library";
const ADD_BTN = "Add an avatar";
const PICK_AVATAR_TITLE = "Pick Your Avatar";

interface AvatarSelectionProps {
  setShowAvatarSelection: (show: boolean) => void;
  setSelectedImagePrompt: (show: string) => void;
}

export default function AvatarSelection({
  setShowAvatarSelection,
  setSelectedImagePrompt,
}: AvatarSelectionProps) {
  const [showModal, setShowModal] = useState(false);
  const { avatar, avatarList, setAvatar, fetchData } = useAvatarSelection();
  const [selectedImage, setSelectedImage] = useState("");

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  useEffect(() => {
    if (avatar.id) {
      const selectedAvatar = avatarList.find(
        (a: any) => a.dreambooth_id === avatar.id
      ) as any;

      if (selectedAvatar) {
        setSelectedImagePrompt(selectedAvatar.imageAvatar);
        setSelectedImagePrompt(selectedAvatar.imageAvatar);
        setSelectedImage(selectedAvatar.imageAvatar);
        setAvatar(
          selectedAvatar
            ? {
                name: selectedAvatar.name,
                id: selectedAvatar.dreambooth_id,
                date: selectedAvatar.date,
              }
            : { name: "", id: "", date: "" }
        );
      }
    }
  }, [avatar.id, avatarList]);

  const chooseAvatar = () => {
    setShowModal(true);
    setShowAvatarSelection(false);
    // fetchData();
  };

  const closeAvatarModal = () => {
    setShowModal(false);
    setShowAvatarSelection(true);
  };

  const renderTooltip = (props: any) => (
    <Tooltip id="avatar-tooltip" {...props}>
      If you want to use one of your avatars in the video you need to select it
      by clicking on the <strong>Add an Avatar</strong> button on the right and
      then writing the phrase {"{avatar}"} into your prompt.
      {""}
      {""}
      {""}
      <div style={{ color: "#ff30c4" }}>
        <strong>For example:</strong>
      </div>
      <div
        style={{
          backgroundColor: "#343333",
          borderColor: "gray",
          borderRadius: "10px",
        }}
      >
        <i style={{ color: "#FFF" }}>close up of {"{avatar}"} playing guitar</i>
        .
      </div>
      If you have not generated an avatar yet, you can create a new one by
      clicking on <strong>“Create an Avatar”</strong> from the menu on the left.
    </Tooltip>
  );

  const renderTooltipMobile = (props: any) => (
    <Tooltip id="avatar-tooltip" {...props}>
      If you want to use one of your avatars in the video you need to select it
      by clicking on the <strong>Add an Avatar</strong> button and then writing
      the phrase {"{avatar}"} into your prompt.
      {""}
      {""}
      {""}
      <div style={{ color: "#ff30c4" }}>
        <strong>For example:</strong>
      </div>
      <div
        style={{
          backgroundColor: "#343333",
          borderColor: "gray",
          borderRadius: "10px",
        }}
      >
        <i style={{ color: "#FFF" }}>close up of {"{avatar}"} playing guitar</i>
        .
      </div>
      If you have not generated an avatar yet, you can create a new one by
      clicking on <strong>“Create an Avatar”</strong>.
    </Tooltip>
  );

  return (
    <div
      className={`d-flex flex-column text-center justify-content-center align-items-center ${
        isMobileDevice() ? "mb-2" : ""
      } gap-2`}
    >
      {!isMobileDevice() && <h1>{TITLE}</h1>}
      <OverlayTrigger
        placement={isMobileDevice() ? "bottom" : "left"}
        overlay={isMobileDevice() ? renderTooltipMobile : renderTooltip}
        delay={{ show: 250, hide: 400 }}
      >
        <div
          style={{ width: "9.5rem", height: "9.5rem", borderRadius: "50%" }}
          className="d-flex flex-column align-items-center justify-content-center border"
          onClick={chooseAvatar}
        >
          {avatar.id && (
            <i className={selectedImage} style={{ fontSize: "5rem" }} />
          )}
          {!avatar.id && (
            <i className="bi bi-slash-circle" style={{ fontSize: "5rem" }} />
          )}
          {avatar.id && (
            <img
              className="border d-flex flex-column align-items-center justify-content-center"
              width={"100%"}
              height={"100%"}
              style={{ borderRadius: "50%" }}
              src={selectedImage}
            />
          )}
        </div>
      </OverlayTrigger>
      <p className="p-0 m-0">{avatar.name}</p>
      <div>
        <p className="text-nowrap m-0">{DESCRIPTION1}</p>
      </div>
      <div onClick={chooseAvatar} className="muze-btn">
        <i className="bi bi-plus" />
        <span>{ADD_BTN}</span>
      </div>
      {showModal && (
        <Modal
          close={closeAvatarModal}
          setShowModal={setShowModal}
          setSelectedImage={setSelectedImage}
        />
      )}
    </div>
  );
}

function Modal(props: any) {
  const { close, setShowModal, setSelectedImage } = props;
  return (
    <div
      className={"d-flex flex-column align-items-center justify-content-center"}
      style={{
        position: "absolute",
        backgroundColor: "#000C",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
      }}
    >
      <PickAvatar {...{ close, setShowModal, setSelectedImage }} />
    </div>
  );
}

function PickAvatar(props: any) {
  const { close, setShowModal, setSelectedImage } = props;
  const navigate = useNavigate();
  const { loading, noavatars } = useAvatarSelection();

  return (
    <div
      className="d-flex flex-column border rounded p-3 m-5"
      style={{ backgroundColor: "black", opacity: 1 }}
    >
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ position: "relative" }}
      >
        <h1 className="mx-5 mb-3">{PICK_AVATAR_TITLE}</h1>
        <i
          className="bi bi-x btn btn-dark border d-flex p-1"
          style={{
            position: "absolute",
            right: "0.01rem",
            top: "0.01rem",
          }}
          onClick={close}
        />
      </div>
      <ChooseAvatar {...{ setShowModal, setSelectedImage }} />
      {noavatars && (
        <h5>
          you don't have any avatars yet ...{" "}
          <i
            className="bi bi-emoji-frown-fill"
            style={{ marginRight: "0.5rem", color: "#FF30C4" }}
          ></i>
        </h5>
      )}
      {noavatars && (
        <div>
          <p
            className="btn btn-create-avatar"
            onClick={() => navigate("/app/create-avatar")}
          >
            Create an Avatar
          </p>
        </div>
      )}
      {loading && !noavatars && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}

function ChooseAvatar(props: any) {
  const { setShowModal, setSelectedImage } = props;

  const [avatars, setAvatars] = useState([] as any);
  const [noAvatar, setNoAvatar] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [imageAvatar, setImageAvatar] = useState("");
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const owner_default = "leeork+10@mymuze.art";

  const { data, isSuccess, isError } = useGetAvatarsQuery({
    ownerId,
  });

  const { data: dataDefault, isSuccess: isSuccessDefault } =
    useGetMetadataDefaultQuery({
      owner_default,
    });

  const { themeId } = useArtStyleSelector();

  const defaultNoneAvatar = {
    name: "None",
    dreambooth_id: "",
    firstImage: "slash-circle",
  };

  useEffect(() => {
    setIsLoading(true);
    getAvatarsList(currentUser, data, dataDefault, themeId).then(
      ({ avatarsData, imageAvatar: imgAvatar }) => {
        const avatarList = [defaultNoneAvatar, ...avatarsData];
        setAvatars(avatarList);
        setImageAvatar(imgAvatar);
        setNoAvatar(avatarList.length === 0);
        setIsLoading(false);
      }
    );
  }, [isSuccess, isSuccessDefault]);

  return (
    <div className="d-flex">
      {!isLoading && !isError && avatars.length > 1 && (
        <div className="choose-avatar-box overflow-hidden">
          <div className="ms-auto p-1">
            <Search />
          </div>
          <div className="d-flex flex-grow-1 flex-wrap justify-content-start overflow-auto p-3 gap-3">
            {avatars.map((avatarObj: any, key: number) => (
              <AvatarCard
                key={key}
                avatarObj={avatarObj}
                setShowModal={setShowModal}
                setSelectedImage={setSelectedImage}
                imageAvatar={imageAvatar}
                defaultImage={
                  !avatarObj.dreambooth_id
                    ? "bi bi-slash-circle"
                    : avatarObj.imageAvatar
                }
              />
            ))}
            {noAvatar && <Nothing />}
            {isLoading && <Spinner />}
          </div>
        </div>
      )}
      {(isError || isLoading) && (
        <div className="d-flex justify-content-center align-items-center border flex-grow-1 p-5">
          {isLoading && <Spinner />}
          {isError && <LoadingError />}
        </div>
      )}
    </div>
  );
}

function AvatarCard(props: any) {
  const { avatar, setAvatar } = useAvatarSelection();
  const { avatarObj, setShowModal, defaultImage, setSelectedImage } = props;
  const { name, date, dreambooth_id, firstImage } = avatarObj;
  const selected = avatar.id === dreambooth_id;

  const onDelete = () => console.log("delete avatar: ", name, dreambooth_id); // TODO: delete avatar
  const onClick = () => {
    if (dreambooth_id === "") {
      setAvatar(
        selected
          ? { name: "", id: "", date: "" }
          : { ...avatarObj, id: dreambooth_id }
      );
    } else {
      setAvatar(
        selected
          ? { name: "", id: "", date: "" }
          : { ...avatarObj, id: dreambooth_id }
      );
    }

    setShowModal(false);
    setSelectedImage(defaultImage);
  };

  const onTrash = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onDelete();
  };
  return (
    <div
      className={`${
        selected ? "avatar-card-selected" : "avatar-card"
      } rounded d-flex flex-column align-items-center pb-3`}
      {...{ onClick }}
    >
      <div className="border-bottom d-flex align-items-center justify-content-between ps-3 gap-3 col-12">
        <div>{date}</div>
        {dreambooth_id !== "" && (
          <i className="btn btn-dark bi bi-trash" {...{ onClick: onTrash }} />
        )}
      </div>
      {firstImage ? (
        <i
          className={defaultImage}
          style={{ fontSize: "6.5rem", height: "7.5rem" }}
        />
      ) : defaultImage === "bi bi-slash-circle" ? (
        <i
          className={defaultImage}
          style={{ fontSize: "6.5rem", height: "7.5rem" }}
        />
      ) : (
        <img
          src={defaultImage}
          alt={name}
          style={{ width: "100%", height: "65%", objectFit: "cover" }}
        />
      )}
      <div className="text-muted m-auto avatar-card-name">{name}</div>
      <div className="muze-btn">Select</div>
    </div>
  );
}

function Search() {
  const [search, setSearch] = useState("");
  return (
    <div
      className="d-flex gap-3 align-items-center"
      style={{ position: "relative" }}
    >
      <input
        type="text"
        placeholder="Search"
        onChange={({ target: { value } }) => setSearch(value)}
        value={search}
      />
      {search === "" ? (
        <i className="bi bi-search search-inner-btn" />
      ) : (
        <i className="bi bi-x search-inner-btn" onClick={() => setSearch("")} />
      )}
    </div>
  );
}

function Nothing() {
  return (
    <div className="d-flex flex-grow-1 align-items-center justify-content-center">
      <h3>No avatar found ...</h3>
    </div>
  );
}

function Loading() {
  return (
    <div className="d-flex flex-grow-1 align-items-center justify-content-center">
      <h3>Loading ...</h3>
    </div>
  );
}

function LoadingError() {
  return (
    <div className="d-flex flex-grow-1 flex-column gap-3 align-items-center justify-content-center">
      <h5>Something went wront ...</h5>
      <p>Please try later.</p>
    </div>
  );
}
