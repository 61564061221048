import { useEffect, useRef, useState } from "react";
import { commonImageTypes, validFileType } from "../CreateAvatar/utils";
import { useGetImageAssetsQuery } from "../../API";
import { useStartingFrame } from "../../store/hooks";
import { APIClient } from "../../utils/services";
import { NextPrev } from "../AudioPage";
import { useAuth } from "../../AuthProvider";
import toast, { Toaster } from "react-hot-toast";

import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import usePaginator from "../Paginator/usePaginator";
import Paginator from "../Paginator";
import axios, { AxiosProgressEvent } from "axios";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import mixpanel from "mixpanel-browser";
import VideosUploaded from "./VideosUploaded";
import { ProgressBar } from "react-bootstrap";

const imageBucketBaseURL = process.env.REACT_APP_IMAGES_BUCKET_URL;
const {
  REACT_APP_MUZE_API_HOST,
  REACT_APP_IMAGES_CDN_URL,
  REACT_APP_RESTYLE_PROD_CDN_URL,
} = process.env;
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "";
mixpanel.init(MIXPANEL_TOKEN);

const TITLE = "Restyle a video";
const TITLE_ = "Transform an existing video's style";
const SUBTITLE = "Upload the video you want to restyle";
const SUBTITLE_ = "Max video length 1 min";
const UPLOAD = "Upload";
const CFY_LYBRARY = "Choose from your library";
const MESSAGE = "Uploaded images will appears here.";

export default function RestyleVideoPage() {
  const [isUpload, setIsUpload] = useState(true);
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const [progress, setProgress] = useState(0);

  const { copyInfo } = location.state || {};

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmallScreen = windowWidth < 1600;

  useEffect(() => {
    if (copyInfo) {
      setIsUpload(false);
    }
  }, [copyInfo]);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div className="ps rounded m-2 d-flex flex-grow-1 flex-column align-items-center overflow-hidden">
          <div
            className={`d-flex flex-column flex-grow-1 align-items-center w-100 ${
              isMobileDevice() ? "p-1" : isSmallScreen ? "p-3" : "p-5"
            }`}
          >
            {isSmallScreen && (
              <div style={{ width: "60%" }}>
                {isLoadingProgress && (
                  <ProgressBar
                    now={progress}
                    variant="success"
                    style={{ backgroundColor: "#ff30c4" }}
                    label={`${progress}%`}
                  />
                )}
              </div>
            )}
            <h1
              className={
                isSmallScreen
                  ? "p-3 d-flex text-center mb-4 fw-bold"
                  : "fw-bold my-5"
              }
            >
              {TITLE}
            </h1>
            <h5>{TITLE_}</h5>

            <Tabs setIsUpload={setIsUpload} isUpload={isUpload} />
            <div className="d-flex justify-content-center col-12">
              {isUpload && (
                <UploadBtn
                  setIsUpload={setIsUpload}
                  setIsLoadingProgress={setIsLoadingProgress}
                  isLoadingProgress={isLoadingProgress}
                  setProgress={setProgress}
                  progress={progress}
                />
              )}

              {!isUpload && <VideosUploaded />}
            </div>
          </div>
          {!isSmallScreen && (
            <div style={{ width: "60%" }}>
              {isLoadingProgress && (
                <ProgressBar
                  variant="success"
                  style={{ backgroundColor: "#ff30c4" }}
                  now={progress}
                  label={`${progress}%`}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="ps rounded m-3 d-flex flex-grow-1 flex-column align-items-center overflow-hidden">
          <div
            className={`d-flex flex-column flex-grow-1 align-items-center w-100 ${
              isSmallScreen ? "p-3" : "p-5"
            }`}
          >
            <h1
              className={
                isSmallScreen
                  ? "p-2 d-flex text-center fw-bold"
                  : "fw-bold my-5"
              }
            >
              {TITLE}
            </h1>
            <h5 className="mb-5">{TITLE_}</h5>
            <div style={{ width: "40%", marginBottom: "35px" }}>
              {isLoadingProgress && (
                <ProgressBar
                  variant="success"
                  style={{ backgroundColor: "#ff30c4" }}
                  now={progress}
                  label={`${progress}%`}
                />
              )}
            </div>

            <Tabs setIsUpload={setIsUpload} isUpload={isUpload} />
            {isUpload && (
              <UploadBtn
                setIsUpload={setIsUpload}
                setProgress={setProgress}
                progress={progress}
                setIsLoadingProgress={setIsLoadingProgress}
                isLoadingProgress={isLoadingProgress}
              />
            )}

            {!isUpload && <VideosUploaded />}
          </div>
        </div>
      )}
    </>
  );
}

function ImageCard(props: any) {
  const { src, name } = props;
  const { setUserInitialImageKey, initialImageKey } = useStartingFrame();

  const downloadImage = () => {
    const link = document.createElement("a");
    link.href = src;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const selected = name === initialImageKey;

  const className = `d-flex flex-column align-items-center justify-content-center image-card ${
    selected && "image-selected"
  }`;

  const onClick = () => setUserInitialImageKey(selected ? "" : name);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div
          {...{ className, onClick }}
          style={{
            width: "100%",
            marginBottom: "0.5rem",
            marginRight: "10px",
            maxWidth: "44%",
            position: "relative",
          }}
        >
          <img
            className="d-flex flex-column align-items-center justify-content-center"
            src={`${src}`}
            alt="initial frame"
          />
          <button
            className="btn btn-download position-absolute"
            onClick={downloadImage}
            style={{
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "70%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <i
              className="bi bi-cloud-arrow-down-fill"
              style={{ fontSize: "large" }}
            ></i>
          </button>
        </div>
      ) : (
        <div className={`${className} position-relative`} onClick={onClick}>
          <button
            className="btn btn-download position-absolute"
            onClick={downloadImage}
            style={{
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "70%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <i
              className="bi bi-cloud-arrow-down-fill"
              style={{ fontSize: "large" }}
            ></i>
          </button>
          <img
            className="d-flex flex-column align-items-center justify-content-center"
            src={`${src}`}
            alt="initial frame"
            style={{
              width: "100%",
              height: "100%",
              display: "block",
            }}
          />
        </div>
      )}
    </>
  );
}

function ChooseFromYourLibrary({ setNoImages }: { setNoImages: any }) {
  const { refetch, isLoading, isError } = useImageLibrary();
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const paginator = usePaginator();
  const { actual, setTotal } = paginator;
  const page = actual;
  const perPage = 10;
  const [formatedImages, setFormatedImages] = useState<any[]>([]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setNoImages(formatedImages.length === 0);
  }, [formatedImages.length, setNoImages]);

  const getImages = () => {
    axios
      .get(
        `${REACT_APP_MUZE_API_HOST}/initial-image/assets?owner_id=${encodeURIComponent(
          ownerId
        )}&page=${page}&per_page=${perPage}`
      )
      .then((res) => {
        if (res.data.results.length === 0 || !Array.isArray(res.data.results)) {
          setTotal(0);
          setFormatedImages([]);
          return;
        }
        const formatedImages = res.data.results.map(
          (el: any, index: number, array: any[]) => ({
            src: `${REACT_APP_IMAGES_CDN_URL}temp/${encodeURIComponent(
              ownerId
            )}/${encodeURIComponent(el.Key)}`,
            name: el.Key,
            date: new Date(el.LastModified).toLocaleDateString(),
            isLast: index === array.length - 1,
          })
        );
        setTotal(res.data.total);
        setFormatedImages(formatedImages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  useCustomEventListener("onImageUpload", () => {
    getImages();
  });

  useEffect(() => {
    getImages();
  }, [actual]);

  return (
    <div className="choose-from-library overflow-hidden">
      {!isLoading && !isError && (
        <>
          <div className="d-flex flex-wrap justify-content-start overflow-auto p-3 gap-3">
            {formatedImages.length > 0 ? (
              formatedImages.map((image: any) => (
                <ImageCard key={image.name} {...image} />
              ))
            ) : (
              <div>{formatedImages.length === 0 ? MESSAGE : null}</div>
            )}
          </div>
          <div>
            <Paginator {...paginator} />
          </div>
        </>
      )}
      {isLoading && <Loading />}
      {isError && <Error />}
    </div>
  );
}

function SpinnerSmallScreen() {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="spinner-border"
        style={{ width: "2rem", height: "2rem" }}
        role="status"
      />
    </div>
  );
}

function Spinner() {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="spinner-border"
        style={{ width: "5rem", height: "5rem" }}
        role="status"
      />
    </div>
  );
}

const allowedVideoTypes = ["video/mp4"];

function UploadBtn(props: any) {
  const {
    setIsUpload,
    setIsLoadingProgress,
    isLoadingProgress,
    setProgress,
    progress,
  } = props;
  const API_KEY = process.env.REACT_APP_MUZE_API_KEY; // Substitua com a sua própria chave de API
  const client = new APIClient(); // Substitua com a inicialização da sua própria API client
  const { currentUser } = useAuth(); // Substitua com a sua própria lógica de obtenção de usuário autenticado
  const ownerId = currentUser?.email || "";
  const inputFile = useRef<HTMLInputElement | null>(null);

  const navigate = useNavigate();

  const navigateToRestylePage = (videoInfo: any) => {
    console.log(videoInfo);
    navigate("/app/restyle", {
      state: {
        videoInfo: {
          url: videoInfo.url,
          name: videoInfo.name,
          width: videoInfo.width,
          height: videoInfo.height,
        },
      },
    });
  };

  const onClick = () => {
    if (!isLoadingProgress) {
      inputFile.current?.click();
    }
  };

  const MIN_WIDTH_LANDSCAPE = 768;
  const MIN_HEIGHT_LANDSCAPE = 480;

  const MIN_WIDTH_PORTRAIT = 480;
  const MIN_HEIGHT_PORTRAIT = 768;

  const validateVideoDimensions = (width: number, height: number): boolean => {
    if (
      (width >= MIN_WIDTH_LANDSCAPE && height >= MIN_HEIGHT_LANDSCAPE) ||
      (width >= MIN_WIDTH_PORTRAIT && height >= MIN_HEIGHT_PORTRAIT)
    ) {
      return true;
    }

    return false;
  };

  const uploadExternalVideo = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0]; // Assume single file selection
      if (!isValidVideoExtension(file)) {
        setIsLoadingProgress(false);
        toast.error(
          `Invalid file extension: ${file.name}. Only .mp4 files are allowed.`,
          {
            duration: 5000,
          }
        );
        return;
      }
      if (!isValidVideoType(file)) {
        setIsLoadingProgress(false);
        toast.error(`Format not allowed: ${file.name}`, {
          duration: 5000,
        });
        return;
      }
      if (/\s/.test(file.name)) {
        toast.error(
          "File name cannot contain spaces. Please use an underscore or hyphen instead.",
          {
            duration: 4000,
          }
        );
        setIsLoadingProgress(false);
        return;
      }
      setIsLoadingProgress(true);
      setProgress(0);
      toast.success(`Sending original video ${file.name} to video assets`);

      try {
        const videoDimensions = await getVideoDimensions(file);
        const { width, height } = videoDimensions;

        if (!validateVideoDimensions(width, height)) {
          toast.error(
            `Video dimensions are not acceptable. Please ensure the width and height meet at least one of the following criteria: ${MIN_WIDTH_LANDSCAPE}x${MIN_HEIGHT_LANDSCAPE} (landscape) or ${MIN_WIDTH_PORTRAIT}x${MIN_HEIGHT_PORTRAIT} (portrait).`,
            {
              duration: 6000,
            }
          );
          setIsLoadingProgress(false);
          return;
        }

        client.upload_external_video(
          ownerId,
          file,
          (response: any) => {
            const src = `${REACT_APP_RESTYLE_PROD_CDN_URL}/${ownerId}/original-videos/${file.name}`;

            toast.success(`Uploading video ${file.name} to video assets`);
            setIsLoadingProgress(false);
            emitCustomEvent("onVideoUpload");
            setIsUpload(false);
            navigateToRestylePage({
              url: src,
              name: file.name,
              width,
              height,
            });
          },
          (error: any) => {
            toast.error(
              `Failed to upload video ${file.name}: ${error.message}`
            );
            setIsLoadingProgress(false);
          },
          (progressEvent: AxiosProgressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentCompleted);
            }
          }
        );
      } catch (error: any) {
        toast.error(`Failed to extract video dimensions: ${error.message}`);
        setIsLoadingProgress(false);
      }
    }
  };

  const getVideoDimensions = async (
    file: Blob
  ): Promise<{ width: number; height: number }> => {
    return new Promise<{ width: number; height: number }>((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        resolve({ width: video.videoWidth, height: video.videoHeight });
      };
      video.src = URL.createObjectURL(file);
    });
  };

  const isValidVideoType = (file: File) => {
    return allowedVideoTypes.includes(file.type);
  };

  const isValidVideoExtension = (file: File) => {
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    return fileExtension === "mp4";
  };

  const inp = {
    id: "file",
    ref: inputFile,
    style: { display: "none" },
    accept: allowedVideoTypes.join(","),
  };

  return (
    <div
      className={`upload-btn ${isLoadingProgress ? "disabled" : ""}`}
      onClick={onClick}
      style={{ pointerEvents: isLoadingProgress ? "none" : "auto" }}
    >
      <input type="file" {...inp} onChange={uploadExternalVideo} />
      <i className="bi bi-upload" />
      <p>{SUBTITLE}</p>
      <p>{SUBTITLE_}</p>
    </div>
  );
}

function Tabs(props: any) {
  const { isUpload, setIsUpload } = props;

  return (
    <div className="d-flex flex-column overflow-auto gap-3">
      <div className="d-flex justify-content-center gap-5">
        <div
          onClick={() => setIsUpload(true)}
          style={{ borderBottom: isUpload ? "2px #FF30C4 solid" : "" }}
        >
          {UPLOAD}
        </div>
        <div
          onClick={() => setIsUpload(false)}
          style={{ borderBottom: !isUpload ? "2px #FF30C4 solid" : "" }}
        >
          {CFY_LYBRARY}
        </div>
      </div>
    </div>
  );
}

function Loading() {
  return (
    <div className="d-flex flex-grow-1 align-items-center justify-content-center">
      <h3>Loading ...</h3>
    </div>
  );
}

function Error() {
  return (
    <div className="d-flex flex-grow-1 flex-column gap-3 align-items-center justify-content-center">
      <h5>Something went wront ...</h5>
      <p>Please try later.</p>
    </div>
  );
}

function useImageLibrary() {
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const paginator = usePaginator();
  const { actual, setTotal, setItemsPerPage, itemsPerPage } = paginator;
  const page = actual;
  const perPage = itemsPerPage;
  const { data, isLoading, refetch, isError } = useGetImageAssetsQuery({
    ownerId,
    page,
    perPage,
  });
  const { setUserInitialImageKey, initialImageKey } = useStartingFrame();

  const images = Array.isArray(data?.results)
    ? data.results.map((i: any, k: number, l: any[]) => ({
        img: `${imageBucketBaseURL}/${ownerId}/${i.Key}`,
        id: i.Key,
        isLast: k === l.length - 1,
      }))
    : [];

  return {
    refetch,
    images,
    isLoading,
    isError,
    setUserInitialImageKey,
    initialImageKey,
  };
}
