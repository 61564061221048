import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthProvider";
import "../styles.css";
import { APIClient } from "../../../utils/services";
import { useEffect, useState } from "react";
import ModalCancelSubscription from "./ModalCancelSubscription";
import { Spinner } from "react-bootstrap";
import Pricing from "../Pricing";
import { capitalize } from "../../../utils";
import ModalConfirmationCancel from "./ModalConfirmationCancel";

interface SubscriptionData {
  user_credits: {
    Item: {
      account_type: string;
      medias_limit: number;
      medias_used: number;
      audios_limit: number;
      audios_used: number;
    } | null;
  };
}

interface AccountInfoPaddle {
  subscriptions: {
    id: string;
    status: string;
    customer_id: string;
    address_id: string;
    business_id: string | null;
    currency_code: string;
    created_at: string;
    updated_at: string;
    started_at: string;
    first_billed_at: string;
    next_billed_at: string;
    paused_at: string | null;
    canceled_at: string | null;
    collection_mode: string;
    billing_details: any | null;
    current_billing_period: {
      starts_at: string;
      ends_at: string;
    };
    custom_data: any | null;
    management_urls: {
      update_payment_method: string;
      cancel: string;
    };
    discount: {
      id: string;
      starts_at: string;
      ends_at: string;
      type: string;
    } | null;
    import_meta: any | null;
  }[];
}

export default function Billing() {
  const navigate = useNavigate();
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const className = `d-flex flex-column justify-content-start flex-grow-1 text-center border rounded ${
    isMobileDevice() ? "overflow-auto" : ""
  }`;
  const style = {
    height: isMobileDevice() ? "600px" : "100%",
    maxWidth: "100%",
  };

  const service = new APIClient();
  const [showCancelPlan, setShowCancelPlan] = useState(false);
  const [showErroreCancelPlan, setShowErrorCancelPlan] = useState(false);
  const [showConfirmCancelPlan, setShowConfirmCancelPlan] = useState(false);
  const [acccountInfoPaddle, setAccountInfoPaddle] =
    useState<AccountInfoPaddle>();
  const [showPurchase, setShowPurchase] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAccountTypeLoading, setIsAccountTypeLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionData>({
    user_credits: {
      Item: null,
    },
  });

  const { currentUser } = useAuth();
  const ownerId = `${currentUser?.email}`;

  useEffect(() => {
    service
      .get_plan_subscription(ownerId)
      .then((res: any) => {
        setLoading(false);
        setSubscriptionData(res.data);
        setAccountInfoPaddle(res.data);
      })
      .catch((error: any) => {
        console.error("Error fetching plan subscription:", error);
      });
  }, [ownerId]);

  const logOut = () => {
    navigate("/");
  };

  const backDashboard = () => {
    navigate("/app/dashboard");
  };

  const nextBilledAt =
    acccountInfoPaddle?.subscriptions[0]?.next_billed_at !== undefined &&
    acccountInfoPaddle.subscriptions[0]?.next_billed_at;
  const mediaRemaining =
    subscriptionData?.user_credits?.Item?.medias_limit !== undefined &&
    subscriptionData.user_credits.Item?.medias_limit -
      subscriptionData.user_credits.Item?.medias_used;
  const audioRemaining =
    subscriptionData?.user_credits?.Item?.audios_limit !== undefined &&
    subscriptionData.user_credits?.Item?.audios_limit -
      subscriptionData.user_credits?.Item?.audios_used;
  const accountType = subscriptionData?.user_credits?.Item?.account_type
    ? capitalize(subscriptionData.user_credits?.Item?.account_type)
    : "...";

  const cancelSubscription = () => {
    setLoading(true);
    service
      .get_plan_subscription(ownerId)
      .then((res: any) => {
        const subscriptions = res.data.subscriptions;
        const activeSubscription = subscriptions.find(
          (subscription: any) =>
            subscription.status === "trialing" ||
            subscription.status === "active"
        );

        if (activeSubscription) {
          const subscriptionId = activeSubscription.id;

          service
            .paddle_cancel_subscription(subscriptionId)
            .then((res: any) => {
              setShowCancelPlan(true);
              setShowConfirmCancelPlan(false);
              setShowConfirmCancelPlan(false);
              setLoading(false);
            })
            .catch((error: any) => {
              console.log(error);
              setShowErrorCancelPlan(true);
              setLoading(false);
            });
        }
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
  };

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" } as const;
    return date.toLocaleDateString("en-US", options);
  }

  return (
    <div {...{ className, style }}>
      {showPurchase && <Pricing />}
      {showConfirmCancelPlan && (
        <ModalConfirmationCancel
          show={showConfirmCancelPlan}
          onHide={() => setShowConfirmCancelPlan(false)}
          name="Cancel Subscription"
          description="We're sad to see you go! Are you sure you want to cancel your subscription?"
          confirm={cancelSubscription}
          notConfirm={backDashboard}
        />
      )}
      {showErroreCancelPlan && (
        <ModalCancelSubscription
          show={showErroreCancelPlan}
          onHide={() => setShowErrorCancelPlan(false)}
          name="Fail"
          description="Sorry, we are unable to cancel your subscription at this moment. Please contact us for further assistance!"
        />
      )}
      {showCancelPlan && (
        <ModalCancelSubscription
          show={showCancelPlan}
          onHide={() => setShowCancelPlan(false)}
          name="Subscription cancellation scheduled"
          description="Your subscription will be canceled in the next billing period. Take advantage of this period to enjoy our application while your plan is still active!"
          backToDashboard={backDashboard}
        />
      )}
      {showCancelPlan && (
        <ModalCancelSubscription
          show={showCancelPlan}
          onHide={() => setShowCancelPlan(false)}
          name="Subscription cancellation scheduled"
          description="Your subscription will be canceled in the next billing period. Take advantage of this period to enjoy our application while your plan is still active!"
          backToDashboard={backDashboard}
        />
      )}
      {isMobileDevice()
        ? !showPurchase && (
            <>
              <h3
                className="border-bottom p-3 text-start"
                style={{ fontWeight: "bolder" }}
              >
                {title}
              </h3>
              <div className="text-start p-3">
                <h5
                  className="pb-3"
                  style={{ fontWeight: "bold", fontSize: "medium" }}
                >
                  {loading ? (
                    <Spinner />
                  ) : (
                    <div>{`Your Current Plan: ${accountType}`}</div>
                  )}
                </h5>
                <div className="d-flex">
                  <i
                    className="bi bi-film"
                    style={{
                      marginRight: "10px",
                      color: "#FF30C4",
                      fontSize: "18px",
                    }}
                  ></i>
                  <p>{`You have ${mediaRemaining} media credits remaining`}</p>
                </div>
                <div className="d-flex">
                  <i
                    className="bi bi-music-note-beamed"
                    style={{
                      marginRight: "10px",
                      color: "#FF30C4",
                      fontSize: "18px",
                    }}
                  ></i>
                  <p>{`You have ${audioRemaining} audio credits remaining`}</p>
                </div>
                <div className="d-flex">
                  <i
                    className="bi bi-calendar-check-fill"
                    style={{
                      marginRight: "10px",
                      color: "#FF30C4",
                      fontSize: "18px",
                    }}
                  ></i>
                  <p
                    style={{ fontWeight: "bold" }}
                  >{`Your credits will be reset on ${
                    nextBilledAt ? formatDate(nextBilledAt) : "..."
                  }`}</p>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <p
                    className="btn btn-manage-account"
                    onClick={() => setShowPurchase(true)}
                  >
                    Manage Plan and Credits
                  </p>
                </div>
                <div>
                  <p
                    className="btn btn-danger"
                    onClick={() => setShowConfirmCancelPlan(true)}
                  >
                    Cancel subscription
                  </p>
                </div>
              </div>
            </>
          )
        : !showPurchase && (
            <>
              <h1
                className="border-bottom p-3 text-start"
                style={{ fontWeight: "bolder" }}
              >
                {title}
              </h1>
              <div className="text-start p-3">
                <h4 className="pb-3" style={{ fontWeight: "bold" }}>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <div>{`Your Current Plan: ${accountType}`}</div>
                  )}
                </h4>
                <div className="d-flex">
                  <i
                    className="bi bi-film"
                    style={{
                      marginRight: "10px",
                      color: "#FF30C4",
                      fontSize: "18px",
                    }}
                  ></i>
                  <p>{`You have ${mediaRemaining} media credits remaining`}</p>
                </div>
                <div className="d-flex">
                  <i
                    className="bi bi-music-note-beamed"
                    style={{
                      marginRight: "10px",
                      color: "#FF30C4",
                      fontSize: "18px",
                    }}
                  ></i>
                  <p>{`You have ${audioRemaining} audio credits remaining`}</p>
                </div>
                <div className="d-flex">
                  <i
                    className="bi bi-calendar-check-fill"
                    style={{
                      marginRight: "10px",
                      color: "#FF30C4",
                      fontSize: "18px",
                    }}
                  ></i>
                  <p
                    style={{ fontWeight: "bold" }}
                  >{`Your credits will be reset on ${
                    nextBilledAt ? formatDate(nextBilledAt) : "..."
                  }`}</p>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <p
                    className="btn btn-manage-account"
                    onClick={() => setShowPurchase(true)}
                  >
                    Manage Plan and Credits
                  </p>
                </div>
                <div>
                  <p
                    className="btn btn-danger"
                    onClick={() => setShowConfirmCancelPlan(true)}
                  >
                    Cancel subscription
                  </p>
                </div>
              </div>
            </>
          )}
    </div>
  );
}

const title = "Billing";
