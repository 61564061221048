import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function ModalRemainCredits(props: any) {
  const { show, onHide } = props;
  const navigate = useNavigate();

  const handleUpgradePlan = () => {
    navigate("/app/settings/billing");
  };

  const backToManagePlan = () => {
    navigate("/app/settings/billing");
  };

  return (
    <Modal {...props} show={show} onHide={onHide} centered>
      <Modal.Header
        style={{
          backgroundColor: "#333",
          color: "#fff",
          borderBottom: "none",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Modal.Title
          style={{
            fontFamily: "Arial",
            fontSize: "1.3rem",
            fontWeight: "bold",
          }}
        >
          <i
            className="bi bi-lightning"
            style={{ marginRight: "0.5rem", color: "#FF30C4" }}
          ></i>
          Enhance Your Subscription
        </Modal.Title>
        <Button
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "#fff",
            fontSize: "1.25rem",
          }}
          onClick={backToManagePlan}
        >
          &times
        </Button>
      </Modal.Header>
      <Modal.Body
        style={{ backgroundColor: "#444", color: "#fff", fontFamily: "Arial" }}
      >
        <h6 style={{ marginBottom: "1rem", fontSize: "1.25rem" }}>
          <i
            className="bi bi-emoji-frown"
            style={{ marginRight: "0.5rem", color: "#FF30C4" }}
          ></i>
          You have reached the limit of media uploads per month
        </h6>
        <p style={{ fontSize: "0.92rem", lineHeight: "1.5" }}>
          To increase your media upload limit per month, upgrade your plan by
          clicking on the button below.
        </p>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#333", borderTop: "none" }}>
        <Button
          style={{
            backgroundColor: "#FF30C4",
            border: "none",
            fontSize: "1.1rem",
            fontWeight: "bold",
            padding: "0.4rem 1rem",
            borderRadius: "0.5rem",
            backgroundImage: "linear-gradient(#FFF, #FF30C4, #FF30C4)",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "all 0.3s ease",
          }}
          onClick={handleUpgradePlan}
        >
          Upgrade Plan
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
