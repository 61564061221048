import React, { useEffect, useRef, useState } from "react";
import { useCheckout } from "./useCheckout";
import { usePlans } from "./usePlans";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../AuthProvider";
import { APIClient } from "../../utils/services";
import ModalStyle from "./modalStyle";
import { Spinner } from "react-bootstrap";
import "./styles.css";

export default function SubscriptionPage() {
  return (
    <div className="d-flex flex-column p-1 m-1 flex-grow-1">
      <Plans />
    </div>
  );
}

export function ListPlans() {
  const { plans } = usePlans();
  const { openCheckout } = useCheckout(undefined);

  const p = plans.sort((a: any, b: any) =>
    +b.unit_price.amount < +a.unit_price.amount ? 1 : -1
  );

  return (
    <div className="d-flex flex-column gap-3 overflow-hidden">
      <p className="text-muted text-small m-0 p-0 text-center">
        Choose your plan:
      </p>
      <div className="d-flex py-3 overflow-auto">
        <div className="d-flex flex-wrap gap-3">
          {/* TEST 100 PLANS */}
          {/* {TEST_PLANS */}
          {p?.map((i: any, key: number, l: Array<any>) => (
            <Plan
              {...{ key, ...i, openCheckout, last: l.length - 1 === key }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function Plan(props: any) {
  const {
    name, // aways returning null (?)
    custom_data, // custom object {name}
    id,
    description,
    status,
    unit_price, // object {currency_code, amount}
    billing_cycle, // object {frequency, interval}
    product_id,
    quantity, // object {maximun, minimun}
    openCheckout,
    last,
  } = props;
  return (
    <div
      className={`border rounded d-flex flex-column flex-column btn btn-dark text-start p-0 ${
        last ? "me-auto" : ""
      }`}
      onClick={() => openCheckout(id)}
    >
      <h2 className="border-bottom px-3 py-1">
        Name: {name || custom_data?.name}
      </h2>
      <div className="d-flex flex-column flex-column px-3">
        <p>Description: {description}</p>
        <p>Status: {status}</p>
        <p>
          {`Price: ${unit_price.currency_code} ${(
            Number(unit_price.amount) / 100
          ).toFixed(2)}          `}
        </p>
        <p>Billing Cycle: {billing_cycle.interval}</p>
      </div>
      <p className="text-muted p-0 m-0 mb-1 px-1 small">Id: {id}</p>
      <p className="text-muted p-0 m-0 mb-1 px-1 small">
        Product Id: {product_id}
      </p>
    </div>
  );
}

function CreditCard() {
  const [formData, setFormData] = useState({
    userName: "",
    cardNumber: "",
    expiration: "",
    cvv: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // subscription processing logic
    // subscribe(formData);
  };
  return (
    <form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
      <label htmlFor="userName">Card Name:</label>
      <input
        type="text"
        id="userName"
        name="userName"
        value={formData.userName}
        onChange={handleChange}
        required
      />
      <br />

      <label htmlFor="cardNumber">Card Number:</label>
      <input
        type="text"
        id="cardNumber"
        name="cardNumber"
        value={formData.cardNumber}
        onChange={handleChange}
        required
      />
      <br />

      <label htmlFor="expiration">Expiration Date:</label>
      <input
        type="text"
        id="expiration"
        name="expiration"
        value={formData.expiration}
        onChange={handleChange}
        placeholder="MM/AA"
        required
      />
      <br />

      <label htmlFor="cvv">CVV:</label>
      <input
        type="text"
        id="cvv"
        name="cvv"
        value={formData.cvv}
        onChange={handleChange}
        required
      />
      <br />

      <input type="submit" value="Subscribe" />
    </form>
  );
}

const TEST_PLANS = Array(101).fill({
  name: "A plan name",
  id: "A plan id",
  description: "A plan description",
  status: "A plan status",
  unit_price: { currency_code: "CUR", amount: "1234" }, // object {currency_code, amount}
  billing_cycle: { frequency: "1", interval: "A interval" }, // object {frequency, interval}
  product_id: "A product id",
  quantity: { maximun: "100", minimun: "1" }, // object {maximun, minimun}
  openCheckout: () => {},
});

const BASE_URL = process.env.REACT_APP_MUZE_API_HOST || "http://localhost:8002";

export function Plans() {
  const { currentUser } = useAuth();
  const { plans, refetch, isLoading, isError } = usePlans();
  const navigate = useNavigate();
  const {
    openCheckout,
    showSuccessModal,
    showErrorModal,
    setShowSuccessModal,
    setShowSuccessFirstSubs,
    showSuccessFirstSubs,
    setShowErrorModal,
    setShowMessageDowngrade,
    showMessageDowngrade,
    loading,
    setUpdateTrialingSub,
    showUpdateTrialingSub,
  } = useCheckout(gotoGV);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function gotoGV(v: any) {
    if (v.name === "checkout.completed") {
      setShowSuccessFirstSubs(true);
      navigate("/app/dashboard");
    }
  }

  function goToDashboard() {
    navigate("/app/dashboard");
  }

  let cards = Array(3);
  if (plans.length > 2) {
    const p = plans.sort((a: any, b: any) =>
      +b.unit_price.amount < +a.unit_price.amount ? 1 : -1
    );
    cards = p.map((plan: any) => ({
      name: plan?.custom_data?.name,
      description: plan?.description,
      price: plan?.unit_price?.amount
        ? (+plan?.unit_price?.amount / 100).toFixed(2)
        : undefined,
      billing: plan?.custom_data?.billing,
      advantages: plan?.custom_data?.advantages,
      callback: () => openCheckout(currentUser?.email || "", plan?.description),
      active: false,
    }));
  }

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div
      className="ps d-flex flex-column align-items-center justify-content-center"
      style={{ height: "100%", whiteSpace: "nowrap" }}
    >
      {(isLoading || isError) && (
        <div
          className="d-flex text-center align-items-center justify-content-center flex-grow-1"
          style={{ width: "100%", height: "100%" }}
          onClick={() => {
            if (isError) {
              refetch();
            }
          }}
        >
          {isLoading ? "Loading..." : "Something went wrong. Please try later."}
          {!isLoading && <i className="bi bi-arrow-clockwise" />}
          {isLoading && <i className="bi bi-arrow-repeat" />}
        </div>
      )}

      {!isLoading && !isError && (
        <div
          className={isMobileDevice() ? "d-flex flex-column" : "d-flex"}
          style={
            isMobileDevice()
              ? {}
              : {
                  backgroundColor: "#141718",
                  borderRadius: "1.5rem",
                }
          }
        >
          <FirstCard {...cards[0]} windowWidth={windowWidth} />
          <MiddleCard {...cards[1]} windowWidth={windowWidth} />
          <WhiteCard {...cards[2]} windowWidth={windowWidth} />
        </div>
      )}

      {showSuccessModal && (
        <ModalStyle
          show={showSuccessModal}
          onHide={() => setShowSuccessModal(false)}
          name="Success"
          description="Your subscription was updated successfully!"
          onStartCreating={goToDashboard}
          buttonLabel="Continue Creating"
        />
      )}

      {showUpdateTrialingSub && (
        <ModalStyle
          show={showUpdateTrialingSub}
          onHide={() => setUpdateTrialingSub(false)}
          name="Success"
          description="Your trialing subscription was updated successfully!"
          onStartCreating={goToDashboard}
          buttonLabel="Continue Creating"
        />
      )}

      {showSuccessFirstSubs && (
        <ModalStyle
          show={showSuccessFirstSubs}
          onHide={() => setShowSuccessFirstSubs(false)}
          name="Success"
          description="Congrats! Your subscription was successfull!"
          onStartCreating={goToDashboard}
          buttonLabel="Start Creating"
        />
      )}

      {showErrorModal && (
        <ModalStyle
          show={showErrorModal}
          onHide={() => setShowErrorModal(false)}
          name="Error"
          description="Your subscription could not be updated at this time. Please try again later."
          onStartCreating={goToDashboard}
          buttonLabel="Ok"
        />
      )}

      {showMessageDowngrade && (
        <ModalStyle
          show={showMessageDowngrade}
          onHide={() => setShowMessageDowngrade(false)}
          name="Success"
          description="Your subscription will be changed on next billing period."
          onStartCreating={goToDashboard}
          buttonLabel="Ok"
        />
      )}
      {loading && <Spinner />}
    </div>
  );
}

function FirstCard(props: any) {
  const { description, windowWidth, advantages, callback } = props;
  const [isLoading, setIsLoading] = useState(false);
  const cardClass = windowWidth < 1700 ? "gap-1" : "gap-2";

  let onClick = () => {};
  if (callback) {
    onClick = callback;
  }

  const { currentUser } = useAuth();
  const client = new APIClient();
  const [ownerId, setOwnerId] = useState<string>("");
  const [isAccountType, setIsAccountType] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if (currentUser && currentUser.email) {
      setOwnerId(currentUser.email);
    }
  }, [currentUser]);

  let account_type;

  useEffect(() => {
    if (ownerId !== null && ownerId !== "") {
      client.getUserByEmail(ownerId).then((res: any) => {
        account_type = res?.account_type;
        setIsAccountType(account_type);
        setIsLoading(false);
      });
    }
  }, [ownerId]);

  const isCurrentPlan = isAccountType === description?.toLowerCase();
  const isFreePlan = isAccountType === "free";
  const isCurrentPlanEnterprise = isAccountType === "enterprise";
  const isCurrentPlanAgency = isAccountType === "agency";

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const cardStyle =
    windowWidth < 1700
      ? {
          backgroundColor: isCurrentPlan ? "#F3F5F7" : "#141718",
          borderRadius: "1.5rem",
          padding: "0.5rem 0.6rem",
          borderLeft: isCurrentPlan ? "" : "solid #232627 1px",
          scale: isCurrentPlan ? "1.075" : undefined,
          marginRight: "1.1rem",
          marginBottom: "2rem",
          height: isMobileDevice() ? "300px" : undefined,
        }
      : {
          backgroundColor: isCurrentPlan ? "#F3F5F7" : "#141718",
          borderRadius: "1.5rem",
          padding: "3rem 2rem",
          borderLeft: isCurrentPlan ? "" : "solid #232627 1px",
          scale: isCurrentPlan ? "1.075" : undefined,
          height: isMobileDevice() ? "300px" : undefined,
        };

  return (
    <div
      className={`d-flex flex-column ${cardClass} ${
        isCurrentPlan ? "ms-3" : ""
      } text-start`}
      style={
        isMobileDevice() ? { ...cardStyle, fontSize: "xx-small" } : cardStyle
      }
    >
      <div className="d-flex gap-2">
        <h2 className="my-auto" style={{ color: "#FF30C4" }}>
          {description || "Rockstar"}
        </h2>
      </div>
      <h5
        className={`${isCurrentPlan ? "text-black" : "text-white"}`}
        style={isMobileDevice() ? { fontSize: "small" } : {}}
      >
        {"For Indie Artists"}
      </h5>
      <div className="d-flex">
        <h1
          style={isMobileDevice() ? { fontSize: "small" } : {}}
          className={`${
            isCurrentPlan ? "text-black mt-auto" : "text-white mt-auto"
          }`}
        >{`$29.00`}</h1>
        <h3 className="my-auto" style={{ color: "#6C7275" }}>
          {/* {" / monthly"} */}
        </h3>
      </div>

      <div
        style={{
          borderColor: "#343839",
          borderBottom: "1px solid",
        }}
      />
      <div className="card-height">
        {advantages ||
          [
            "Up to 50 videos at default settings",
            "3 simultaneous renders at a time",
            "Any aspect radio",
            "Upscale 1080p",
            "Up to 10 Avatars",
          ].map((i, k) => (
            <div key={k} className="d-flex align-items-center gap-1 mt-1">
              <i
                className="bi bi-check text-white my-auto"
                style={{
                  padding: "0.25rem 0.5rem",
                  backgroundColor: "#6C7275",
                  borderRadius: "50%",
                  fontSize: isMobileDevice() ? "xx-small" : undefined,
                }}
              />
              <h6
                style={isMobileDevice() ? { fontSize: "small" } : {}}
                className={`${
                  isCurrentPlan ? "text-black my-auto" : "text-white my-auto"
                }`}
              >
                {i}
              </h6>
            </div>
          ))}
      </div>
      <div
        onClick={onClick}
        className={`${
          !isCurrentPlan ? (isMobileDevice() ? "mt-2" : "mt-55") : ""
        } text-center py-3 btn ${!isCurrentPlan ? "btn-dark" : ""} ${
          isCurrentPlan ? "disabled" : ""
        }`}
        style={{
          ...(isCurrentPlan
            ? {
                backgroundColor: "#FF30C4",
                borderRadius: "0.75rem",
              }
            : {}),
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <h5
            style={isMobileDevice() ? { fontSize: "small" } : {}}
            className="text-white my-auto"
          >
            {isFreePlan
              ? "Start Trial"
              : isCurrentPlan
              ? "Current plan"
              : isCurrentPlanEnterprise || isCurrentPlanAgency
              ? "Downgrade"
              : "Upgrade"}
          </h5>
        )}
      </div>
    </div>
  );
}

function MiddleCard(props: any) {
  const { description, advantages, windowWidth, callback } = props;
  const [isLoading, setIsLoading] = useState(false);
  const cardClass = windowWidth < 1700 ? "gap-1" : "gap-2";

  let onClick = () => {};
  if (callback) {
    onClick = callback;
  }

  const { currentUser } = useAuth();
  const client = new APIClient();
  const [ownerId, setOwnerId] = useState<string>("");
  const [isAccountType, setIsAccountType] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if (currentUser && currentUser.email) {
      setOwnerId(currentUser.email);
    }
  }, [currentUser]);

  let account_type;

  useEffect(() => {
    if (ownerId !== null && ownerId !== "") {
      client.getUserByEmail(ownerId).then((res: any) => {
        account_type = res?.account_type;
        setIsAccountType(account_type);
        setIsLoading(false);
      });
    }
  }, [ownerId]);

  const isCurrentPlan = isAccountType === description?.toLowerCase();
  const isFreePlan = isAccountType === "free";
  const isCurrentPlanEnterprise = isAccountType === "enterprise";

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const cardStyle =
    windowWidth < 1700
      ? {
          backgroundColor: isCurrentPlan || isFreePlan ? "#F3F5F7" : "#141718",
          borderRadius: "1.5rem",
          padding: "0.5rem 0.6rem",
          borderLeft: isCurrentPlan || isFreePlan ? "" : "solid #232627 1px",
          scale: isCurrentPlan || isFreePlan ? "1.075" : undefined,
          marginRight: "1.1rem",
          marginBottom: "2rem",
          height: isMobileDevice() ? "300px" : undefined,
        }
      : {
          backgroundColor: isCurrentPlan || isFreePlan ? "#F3F5F7" : "#141718",
          borderRadius: "1.5rem",
          padding: "3rem 2rem",
          borderLeft: isCurrentPlan || isFreePlan ? "" : "solid #232627 1px",
          scale: isCurrentPlan || isFreePlan ? "1.075" : undefined,
          height: isMobileDevice() ? "300px" : undefined,
        };

  return (
    <div
      className={`d-flex flex-column ${cardClass} ${
        isCurrentPlan || isFreePlan ? "ms-3" : ""
      } text-start`}
      style={
        isMobileDevice() ? { ...cardStyle, fontSize: "xx-small" } : cardStyle
      }
    >
      <div className="d-flex gap-2">
        <h2 className="my-auto" style={{ color: "#FF30C4" }}>
          {description || "Agency"}
        </h2>
        <span
          className="rounded text-white px-2 py-1 my-auto"
          style={{ backgroundColor: "green" }}
        >
          Popular
        </span>
      </div>
      <h5
        className={`${
          isCurrentPlan || isFreePlan ? "text-black" : "text-white"
        }`}
        style={isMobileDevice() ? { fontSize: "small" } : {}}
      >
        {"For agencies & promoters"}
      </h5>
      <div className="d-flex">
        <h1
          style={isMobileDevice() ? { fontSize: "small" } : {}}
          className={`${
            isCurrentPlan || isFreePlan
              ? "text-black mt-auto"
              : "text-white mt-auto"
          }`}
        >{`$99.00`}</h1>
        <h3 className="my-auto" style={{ color: "#6C7275" }}>
          {" / monthly"}
        </h3>
      </div>

      <div
        style={{
          borderColor: "#343839",
          borderBottom: "1px solid",
        }}
      />
      <div className="card-height">
        {advantages ||
          [
            "Up to 150 videos at default settings",
            "5 simultaneous renders at a time",
            "Any aspect radio",
            "Upscale 1080p",
            "Unlimited Avatar creation",
          ].map((i, k) => (
            <div key={k} className="d-flex align-items-center gap-1 mt-1">
              <i
                className="bi bi-check text-white my-auto"
                style={{
                  padding: "0.25rem 0.5rem",
                  backgroundColor: "#6C7275",
                  borderRadius: "50%",
                  fontSize: isMobileDevice() ? "xx-small" : undefined,
                }}
              />
              <h6
                style={isMobileDevice() ? { fontSize: "small" } : {}}
                className={`${
                  isCurrentPlan || isFreePlan
                    ? "text-black my-auto"
                    : "text-white my-auto"
                }`}
              >
                {i}
              </h6>
            </div>
          ))}
      </div>
      <div
        {...{ onClick }}
        className={`${
          !isCurrentPlan ? (isMobileDevice() ? "mt-2" : "mt-55") : ""
        } text-center py-3 btn ${!isCurrentPlan ? "btn-dark" : ""} ${
          isCurrentPlan ? "disabled" : ""
        }`}
        style={{
          ...(isCurrentPlan || isFreePlan
            ? {
                backgroundColor: "#FF30C4",
                borderRadius: "0.75rem",
                borderColor: "#FF30C4",
              }
            : isMobileDevice()
            ? { marginTop: "55px" }
            : {}),
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <h5
            style={isMobileDevice() ? { fontSize: "small" } : {}}
            className={"text-white my-auto"}
          >
            {isFreePlan
              ? "Start Trial"
              : isCurrentPlan
              ? "Current plan"
              : isCurrentPlanEnterprise
              ? "Downgrade"
              : "Upgrade"}
          </h5>
        )}
      </div>
    </div>
  );
}

function WhiteCard(props: any) {
  const { name, description, advantages, windowWidth, callback } = props;
  const [isLoading, setIsLoading] = useState(false);

  let onClick = () => {};
  if (callback) {
    onClick = callback;
  }

  const cardClass = windowWidth < 1700 ? "gap-1" : "gap-2";

  const { currentUser } = useAuth();
  const client = new APIClient();
  const [ownerId, setOwnerId] = useState<string>("");
  const [isAccountType, setIsAccountType] = useState(null);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  useEffect(() => {
    setIsLoading(true);
    if (currentUser && currentUser.email) {
      setOwnerId(currentUser.email);
    }
  }, [currentUser]);

  let account_type;

  useEffect(() => {
    if (ownerId !== null && ownerId !== "") {
      client.getUserByEmail(ownerId).then((res: any) => {
        account_type = res?.account_type;
        setIsAccountType(account_type);
        setIsLoading(false);
      });
    }
  }, [ownerId]);

  const isCurrentPlan = isAccountType === description?.toLowerCase();
  const isCurrentPlanAgency = isAccountType === "agency";
  const isCurrentPlanRockstart = isAccountType === "rockstar";

  const isFreePlan = isAccountType === "free";

  const cardStyle =
    windowWidth < 1700
      ? {
          backgroundColor: isCurrentPlan ? "#F3F5F7" : "#141718",
          borderRadius: "1.5rem",
          padding: "0.5rem 0.6rem",
          borderLeft: isCurrentPlan ? "" : "solid #232627 1px",
          scale: isCurrentPlan ? "1.075" : undefined,
          marginRight: "1.1rem",
          marginBottom: "2rem",
          height: isMobileDevice() ? "300px" : undefined,
        }
      : {
          backgroundColor: isCurrentPlan ? "#F3F5F7" : "#141718",
          borderRadius: "1.5rem",
          padding: "3rem 2rem",
          borderLeft: isCurrentPlan ? "" : "solid #232627 1px",
          scale: isCurrentPlan ? "1.075" : undefined,
          height: isMobileDevice() ? "300px" : undefined,
        };

  return (
    <div
      className={`d-flex flex-column ${cardClass} ${
        isCurrentPlan ? "ms-3" : ""
      } text-start`}
      style={
        isMobileDevice() ? { ...cardStyle, fontSize: "xx-small" } : cardStyle
      }
    >
      <div className="d-flex gap-2">
        <h2 className="my-auto" style={{ color: "#FF30C4" }}>
          {name || "Enterprise"}
        </h2>
      </div>
      <h5
        className={`${isCurrentPlan ? "text-black" : "text-white"}`}
        style={isMobileDevice() ? { fontSize: "small" } : {}}
      >
        {"For labels"}
      </h5>
      <div className="d-flex">
        <h1
          style={isMobileDevice() ? { fontSize: "small" } : {}}
          className={`${
            isCurrentPlan ? "text-black mt-auto" : "text-white mt-auto"
          }`}
        >{`$249.00`}</h1>
        <h3 className="my-auto" style={{ color: "#6C7275" }}>
          {" / monthly"}
        </h3>
      </div>
      <div
        style={{
          borderColor: "#343839",
          borderBottom: "1px solid",
        }}
      />
      <div className="card-height">
        {advantages ||
          [
            "Up to 300 videos at default settings",
            "50 simultaneous renders at a time",
            "Any aspect ratio",
            "Upscale 1080p",
            "Unlimited Avatar creation",
            "1:1 AI consultations",
          ].map((i, k) => (
            <div key={k} className="d-flex align-items-center gap-1 mt-0">
              <i
                className="bi bi-check text-white my-auto"
                style={{
                  padding: "0.25rem 0.5rem",
                  backgroundColor: "#6C7275",
                  borderRadius: "50%",
                  fontSize: isMobileDevice() ? "xx-small" : undefined,
                }}
              />
              <h6
                style={isMobileDevice() ? { fontSize: "small" } : {}}
                className={`${
                  isCurrentPlan ? "text-black my-auto" : "text-white my-auto"
                }`}
              >
                {i}
              </h6>
            </div>
          ))}
      </div>
      <div
        {...{ onClick }}
        className={`mt-3 text-center py-3 btn ${
          !isCurrentPlan ? "btn-dark" : ""
        } ${isCurrentPlan ? "disabled" : ""}`}
        style={{
          ...(isCurrentPlan
            ? {
                backgroundColor: "#FF30C4",
                borderRadius: "0.75rem",
              }
            : isMobileDevice()
            ? { marginTop: "55px" }
            : {}),
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <h5
            style={isMobileDevice() ? { fontSize: "small" } : {}}
            className={"text-white my-auto"}
          >
            {isFreePlan
              ? "Start Trial"
              : isCurrentPlan
              ? "Current plan"
              : isCurrentPlanRockstart || isCurrentPlanAgency
              ? "Upgrade"
              : ""}
          </h5>
        )}
      </div>
    </div>
  );
}
